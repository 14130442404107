export default {
  city: [
    {
      label: '北京市',
      value: 0,
      children: [
        {
          label: '北京市',
          value: 0,
          children: [
            {
              label: '东城区',
              value: 0
            },
            {
              label: '西城区',
              value: 1
            },
            {
              label: '崇文区',
              value: 2
            },
            {
              label: '宣武区',
              value: 3
            },
            {
              label: '朝阳区',
              value: 4
            },
            {
              label: '丰台区',
              value: 5
            },
            {
              label: '石景山区',
              value: 6
            },
            {
              label: '海淀区',
              value: 7
            },
            {
              label: '门头沟区',
              value: 8
            },
            {
              label: '房山区',
              value: 9
            },
            {
              label: '通州区',
              value: 10
            },
            {
              label: '顺义区',
              value: 11
            },
            {
              label: '昌平区',
              value: 12
            },
            {
              label: '大兴区',
              value: 13
            },
            {
              label: '平谷区',
              value: 14
            },
            {
              label: '怀柔区',
              value: 15
            },
            {
              label: '密云县',
              value: 16
            },
            {
              label: '延庆县',
              value: 17
            }
          ]
        }
      ]
    },
    {
      label: '天津市',
      value: 1,
      children: [
        {
          label: '天津市',
          value: 0,
          children: [
            {
              label: '和平区',
              value: 0
            },
            {
              label: '河东区',
              value: 1
            },
            {
              label: '河西区',
              value: 2
            },
            {
              label: '南开区',
              value: 3
            },
            {
              label: '河北区',
              value: 4
            },
            {
              label: '红桥区',
              value: 5
            },
            {
              label: '塘沽区',
              value: 6
            },
            {
              label: '汉沽区',
              value: 7
            },
            {
              label: '大港区',
              value: 8
            },
            {
              label: '东丽区',
              value: 9
            },
            {
              label: '西青区',
              value: 10
            },
            {
              label: '津南区',
              value: 11
            },
            {
              label: '北辰区',
              value: 12
            },
            {
              label: '武清区',
              value: 13
            },
            {
              label: '宝坻区',
              value: 14
            },
            {
              label: '宁河县',
              value: 15
            },
            {
              label: '静海县',
              value: 16
            },
            {
              label: '蓟  县',
              value: 17
            }
          ]
        }
      ]
    },
    {
      label: '河北省',
      value: 2,
      children: [
        {
          label: '石家庄市',
          value: 0,
          children: [
            {
              label: '长安区',
              value: 0
            },
            {
              label: '桥东区',
              value: 1
            },
            {
              label: '桥西区',
              value: 2
            },
            {
              label: '新华区',
              value: 3
            },
            {
              label: '郊  区',
              value: 4
            },
            {
              label: '井陉矿区',
              value: 5
            },
            {
              label: '井陉县',
              value: 6
            },
            {
              label: '正定县',
              value: 7
            },
            {
              label: '栾城县',
              value: 8
            },
            {
              label: '行唐县',
              value: 9
            },
            {
              label: '灵寿县',
              value: 10
            },
            {
              label: '高邑县',
              value: 11
            },
            {
              label: '深泽县',
              value: 12
            },
            {
              label: '赞皇县',
              value: 13
            },
            {
              label: '无极县',
              value: 14
            },
            {
              label: '平山县',
              value: 15
            },
            {
              label: '元氏县',
              value: 16
            },
            {
              label: '赵  县',
              value: 17
            },
            {
              label: '辛集市',
              value: 18
            },
            {
              label: '藁',
              value: 19
            },
            {
              label: '晋州市',
              value: 20
            },
            {
              label: '新乐市',
              value: 21
            },
            {
              label: '鹿泉市',
              value: 22
            }
          ]
        },
        {
          label: '唐山市',
          value: 1,
          children: [
            {
              label: '路南区',
              value: 0
            },
            {
              label: '路北区',
              value: 1
            },
            {
              label: '古冶区',
              value: 2
            },
            {
              label: '开平区',
              value: 3
            },
            {
              label: '新  区',
              value: 4
            },
            {
              label: '丰润县',
              value: 5
            },
            {
              label: '滦  县',
              value: 6
            },
            {
              label: '滦南县',
              value: 7
            },
            {
              label: '乐亭县',
              value: 8
            },
            {
              label: '迁西县',
              value: 9
            },
            {
              label: '玉田县',
              value: 10
            },
            {
              label: '唐海县',
              value: 11
            },
            {
              label: '遵化市',
              value: 12
            },
            {
              label: '丰南市',
              value: 13
            },
            {
              label: '迁安市',
              value: 14
            }
          ]
        },
        {
          label: '秦皇岛市',
          value: 2,
          children: [
            {
              label: '海港区',
              value: 0
            },
            {
              label: '山海关区',
              value: 1
            },
            {
              label: '北戴河区',
              value: 2
            },
            {
              label: '青龙满族自治县',
              value: 3
            },
            {
              label: '昌黎县',
              value: 4
            },
            {
              label: '抚宁县',
              value: 5
            },
            {
              label: '卢龙县',
              value: 6
            }
          ]
        },
        {
          label: '邯郸市',
          value: 3,
          children: [
            {
              label: '邯山区',
              value: 0
            },
            {
              label: '丛台区',
              value: 1
            },
            {
              label: '复兴区',
              value: 2
            },
            {
              label: '峰峰矿区',
              value: 3
            },
            {
              label: '邯郸县',
              value: 4
            },
            {
              label: '临漳县',
              value: 5
            },
            {
              label: '成安县',
              value: 6
            },
            {
              label: '大名县',
              value: 7
            },
            {
              label: '涉  县',
              value: 8
            },
            {
              label: '磁  县',
              value: 9
            },
            {
              label: '肥乡县',
              value: 10
            },
            {
              label: '永年县',
              value: 11
            },
            {
              label: '邱  县',
              value: 12
            },
            {
              label: '鸡泽县',
              value: 13
            },
            {
              label: '广平县',
              value: 14
            },
            {
              label: '馆陶县',
              value: 15
            },
            {
              label: '魏  县',
              value: 16
            },
            {
              label: '曲周县',
              value: 17
            },
            {
              label: '武安市',
              value: 18
            }
          ]
        },
        {
          label: '邢台市',
          value: 4,
          children: [
            {
              label: '桥东区',
              value: 0
            },
            {
              label: '桥西区',
              value: 1
            },
            {
              label: '邢台县',
              value: 2
            },
            {
              label: '临城县',
              value: 3
            },
            {
              label: '内丘县',
              value: 4
            },
            {
              label: '柏乡县',
              value: 5
            },
            {
              label: '隆尧县',
              value: 6
            },
            {
              label: '任  县',
              value: 7
            },
            {
              label: '南和县',
              value: 8
            },
            {
              label: '宁晋县',
              value: 9
            },
            {
              label: '巨鹿县',
              value: 10
            },
            {
              label: '新河县',
              value: 11
            },
            {
              label: '广宗县',
              value: 12
            },
            {
              label: '平乡县',
              value: 13
            },
            {
              label: '威  县',
              value: 14
            },
            {
              label: '清河县',
              value: 15
            },
            {
              label: '临西县',
              value: 16
            },
            {
              label: '南宫市',
              value: 17
            },
            {
              label: '沙河市',
              value: 18
            }
          ]
        },
        {
          label: '保定市',
          value: 5,
          children: [
            {
              label: '新市区',
              value: 0
            },
            {
              label: '北市区',
              value: 1
            },
            {
              label: '南市区',
              value: 2
            },
            {
              label: '满城县',
              value: 3
            },
            {
              label: '清苑县',
              value: 4
            },
            {
              label: '涞水县',
              value: 5
            },
            {
              label: '阜平县',
              value: 6
            },
            {
              label: '徐水县',
              value: 7
            },
            {
              label: '定兴县',
              value: 8
            },
            {
              label: '唐  县',
              value: 9
            },
            {
              label: '高阳县',
              value: 10
            },
            {
              label: '容城县',
              value: 11
            },
            {
              label: '涞源县',
              value: 12
            },
            {
              label: '望都县',
              value: 13
            },
            {
              label: '安新县',
              value: 14
            },
            {
              label: '易  县',
              value: 15
            },
            {
              label: '曲阳县',
              value: 16
            },
            {
              label: '蠡  县',
              value: 17
            },
            {
              label: '顺平县',
              value: 18
            },
            {
              label: '博野',
              value: 19
            },
            {
              label: '雄县',
              value: 20
            },
            {
              label: '涿州市',
              value: 21
            },
            {
              label: '定州市',
              value: 22
            },
            {
              label: '安国市',
              value: 23
            },
            {
              label: '高碑店市',
              value: 24
            },
            {
              label: '雄安新区',
              value: 25
            }
          ]
        },
        {
          label: '张家口',
          value: 6,
          children: [
            {
              label: '桥东区',
              value: 0
            },
            {
              label: '桥西区',
              value: 1
            },
            {
              label: '宣化区',
              value: 2
            },
            {
              label: '下花园区',
              value: 3
            },
            {
              label: '宣化县',
              value: 4
            },
            {
              label: '张北县',
              value: 5
            },
            {
              label: '康保县',
              value: 6
            },
            {
              label: '沽源县',
              value: 7
            },
            {
              label: '尚义县',
              value: 8
            },
            {
              label: '蔚  县',
              value: 9
            },
            {
              label: '阳原县',
              value: 10
            },
            {
              label: '怀安县',
              value: 11
            },
            {
              label: '万全县',
              value: 12
            },
            {
              label: '怀来县',
              value: 13
            },
            {
              label: '涿鹿县',
              value: 14
            },
            {
              label: '赤城县',
              value: 15
            },
            {
              label: '崇礼县',
              value: 16
            }
          ]
        },
        {
          label: '承德市',
          value: 7,
          children: [
            {
              label: '双桥区',
              value: 0
            },
            {
              label: '双滦区',
              value: 1
            },
            {
              label: '鹰手营子矿区',
              value: 2
            },
            {
              label: '承德县',
              value: 3
            },
            {
              label: '兴隆县',
              value: 4
            },
            {
              label: '平泉县',
              value: 5
            },
            {
              label: '滦平县',
              value: 6
            },
            {
              label: '隆化县',
              value: 7
            },
            {
              label: '丰宁满族自治县',
              value: 8
            },
            {
              label: '宽城满族自治县',
              value: 9
            },
            {
              label: '围场满族蒙古族自治县',
              value: 10
            }
          ]
        },
        {
          label: '沧州市',
          value: 8,
          children: [
            {
              label: '新华区',
              value: 0
            },
            {
              label: '运河区',
              value: 1
            },
            {
              label: '沧  县',
              value: 2
            },
            {
              label: '青  县',
              value: 3
            },
            {
              label: '东光县',
              value: 4
            },
            {
              label: '海兴县',
              value: 5
            },
            {
              label: '盐山县',
              value: 6
            },
            {
              label: '肃宁县',
              value: 7
            },
            {
              label: '南皮县',
              value: 8
            },
            {
              label: '吴桥县',
              value: 9
            },
            {
              label: '献  县',
              value: 10
            },
            {
              label: '孟村回族自治县',
              value: 11
            },
            {
              label: '泊头市',
              value: 12
            },
            {
              label: '任丘市',
              value: 13
            },
            {
              label: '黄骅市',
              value: 14
            },
            {
              label: '河间市',
              value: 15
            }
          ]
        },
        {
          label: '廊坊市',
          value: 9,
          children: [
            {
              label: '安次区',
              value: 0
            },
            {
              label: '固安县',
              value: 1
            },
            {
              label: '永清县',
              value: 2
            },
            {
              label: '香河县',
              value: 3
            },
            {
              label: '大城县',
              value: 4
            },
            {
              label: '文安县',
              value: 5
            },
            {
              label: '大厂回族自治县',
              value: 6
            },
            {
              label: '霸州市',
              value: 7
            },
            {
              label: '三河市',
              value: 8
            }
          ]
        },
        {
          label: '衡水市',
          value: 10,
          children: [
            {
              label: '桃城区',
              value: 0
            },
            {
              label: '枣强县',
              value: 1
            },
            {
              label: '武邑县',
              value: 2
            },
            {
              label: '武强县',
              value: 3
            },
            {
              label: '饶阳县',
              value: 4
            },
            {
              label: '安平县',
              value: 5
            },
            {
              label: '故城县',
              value: 6
            },
            {
              label: '景  县',
              value: 7
            },
            {
              label: '阜城县',
              value: 8
            },
            {
              label: '冀州市',
              value: 9
            },
            {
              label: '深州市',
              value: 10
            }
          ]
        }
      ]
    },
    {
      label: '山西省',
      value: 3,
      children: [
        {
          label: '太原市',
          value: 0,
          children: [
            {
              label: '小店区',
              value: 0
            },
            {
              label: '迎泽区',
              value: 1
            },
            {
              label: '杏花岭区',
              value: 2
            },
            {
              label: '尖草坪区',
              value: 3
            },
            {
              label: '万柏林区',
              value: 4
            },
            {
              label: '晋源区',
              value: 5
            },
            {
              label: '清徐县',
              value: 6
            },
            {
              label: '阳曲县',
              value: 7
            },
            {
              label: '娄烦县',
              value: 8
            },
            {
              label: '古交市',
              value: 9
            }
          ]
        },
        {
          label: '大同市',
          value: 1,
          children: [
            {
              label: '城  区',
              value: 0
            },
            {
              label: '矿  区',
              value: 1
            },
            {
              label: '南郊区',
              value: 2
            },
            {
              label: '新荣区',
              value: 3
            },
            {
              label: '阳高县',
              value: 4
            },
            {
              label: '天镇县',
              value: 5
            },
            {
              label: '广灵县',
              value: 6
            },
            {
              label: '灵丘县',
              value: 7
            },
            {
              label: '浑源县',
              value: 8
            },
            {
              label: '左云县',
              value: 9
            },
            {
              label: '大同县',
              value: 10
            }
          ]
        },
        {
          label: '阳泉市',
          value: 2,
          children: [
            {
              label: '城  区',
              value: 0
            },
            {
              label: '矿  区',
              value: 1
            },
            {
              label: '郊  区',
              value: 2
            },
            {
              label: '平定县',
              value: 3
            },
            {
              label: '盂  县',
              value: 4
            }
          ]
        },
        {
          label: '长治市',
          value: 3,
          children: [
            {
              label: '城  区',
              value: 0
            },
            {
              label: '郊  区',
              value: 1
            },
            {
              label: '长治县',
              value: 2
            },
            {
              label: '襄垣县',
              value: 3
            },
            {
              label: '屯留县',
              value: 4
            },
            {
              label: '平顺县',
              value: 5
            },
            {
              label: '黎城县',
              value: 6
            },
            {
              label: '壶关县',
              value: 7
            },
            {
              label: '长子县',
              value: 8
            },
            {
              label: '武乡县',
              value: 9
            },
            {
              label: '沁  县',
              value: 10
            },
            {
              label: '沁源县',
              value: 11
            },
            {
              label: '潞城市',
              value: 12
            }
          ]
        },
        {
          label: '晋城市',
          value: 4,
          children: [
            {
              label: '城  区',
              value: 0
            },
            {
              label: '沁水县',
              value: 1
            },
            {
              label: '阳城县',
              value: 2
            },
            {
              label: '陵川县',
              value: 3
            },
            {
              label: '泽州县',
              value: 4
            },
            {
              label: '高平市',
              value: 5
            }
          ]
        },
        {
          label: '朔州市',
          value: 5,
          children: [
            {
              label: '朔城区',
              value: 0
            },
            {
              label: '平鲁区',
              value: 1
            },
            {
              label: '山阴县',
              value: 2
            },
            {
              label: '应  县',
              value: 3
            },
            {
              label: '右玉县',
              value: 4
            },
            {
              label: '怀仁县',
              value: 5
            }
          ]
        },
        {
          label: '忻州市',
          value: 6,
          children: [
            {
              label: '忻府区',
              value: 0
            },
            {
              label: '原平市',
              value: 1
            },
            {
              label: '定襄县',
              value: 2
            },
            {
              label: '五台县',
              value: 3
            },
            {
              label: '代  县',
              value: 4
            },
            {
              label: '繁峙县',
              value: 5
            },
            {
              label: '宁武县',
              value: 6
            },
            {
              label: '静乐县',
              value: 7
            },
            {
              label: '神池县',
              value: 8
            },
            {
              label: '五寨县',
              value: 9
            },
            {
              label: '岢岚县',
              value: 10
            },
            {
              label: '河曲县',
              value: 11
            },
            {
              label: '保德县',
              value: 12
            },
            {
              label: '偏关县',
              value: 13
            }
          ]
        },
        {
          label: '吕梁市',
          value: 7,
          children: [
            {
              label: '离石区',
              value: 0
            },
            {
              label: '孝义市',
              value: 1
            },
            {
              label: '汾阳市',
              value: 2
            },
            {
              label: '文水县',
              value: 3
            },
            {
              label: '交城县',
              value: 4
            },
            {
              label: '兴  县',
              value: 5
            },
            {
              label: '临  县',
              value: 6
            },
            {
              label: '柳林县',
              value: 7
            },
            {
              label: '石楼县',
              value: 8
            },
            {
              label: '岚  县',
              value: 9
            },
            {
              label: '方山县',
              value: 10
            },
            {
              label: '中阳县',
              value: 11
            },
            {
              label: '交口县',
              value: 12
            }
          ]
        },
        {
          label: '晋中市',
          value: 8,
          children: [
            {
              label: '榆次市',
              value: 0
            },
            {
              label: '介休市',
              value: 1
            },
            {
              label: '榆社县',
              value: 2
            },
            {
              label: '左权县',
              value: 3
            },
            {
              label: '和顺县',
              value: 4
            },
            {
              label: '昔阳县',
              value: 5
            },
            {
              label: '寿阳县',
              value: 6
            },
            {
              label: '太谷县',
              value: 7
            },
            {
              label: '祁  县',
              value: 8
            },
            {
              label: '平遥县',
              value: 9
            },
            {
              label: '灵石县',
              value: 10
            }
          ]
        },
        {
          label: '临汾市',
          value: 9,
          children: [
            {
              label: '临汾市',
              value: 0
            },
            {
              label: '侯马市',
              value: 1
            },
            {
              label: '霍州市',
              value: 2
            },
            {
              label: '曲沃县',
              value: 3
            },
            {
              label: '翼城县',
              value: 4
            },
            {
              label: '襄汾县',
              value: 5
            },
            {
              label: '洪洞县',
              value: 6
            },
            {
              label: '古  县',
              value: 7
            },
            {
              label: '安泽县',
              value: 8
            },
            {
              label: '浮山县',
              value: 9
            },
            {
              label: '吉  县',
              value: 10
            },
            {
              label: '乡宁县',
              value: 11
            },
            {
              label: '蒲  县',
              value: 12
            },
            {
              label: '大宁县',
              value: 13
            },
            {
              label: '永和县',
              value: 14
            },
            {
              label: '隰  县',
              value: 15
            },
            {
              label: '汾西县',
              value: 16
            }
          ]
        },
        {
          label: '运城市',
          value: 10,
          children: [
            {
              label: '运城市',
              value: 0
            },
            {
              label: '永济市',
              value: 1
            },
            {
              label: '河津市',
              value: 2
            },
            {
              label: '芮城县',
              value: 3
            },
            {
              label: '临猗县',
              value: 4
            },
            {
              label: '万荣县',
              value: 5
            },
            {
              label: '新绛县',
              value: 6
            },
            {
              label: '稷山县',
              value: 7
            },
            {
              label: '闻喜县',
              value: 8
            },
            {
              label: '夏  县',
              value: 9
            },
            {
              label: '绛  县',
              value: 10
            },
            {
              label: '平陆县',
              value: 11
            },
            {
              label: '垣曲县',
              value: 12
            }
          ]
        }
      ]
    },
    {
      label: '内蒙古',
      value: 4,
      children: [
        {
          label: '呼和浩特市',
          value: 0,
          children: [
            {
              label: '新城区',
              value: 0
            },
            {
              label: '回民区',
              value: 1
            },
            {
              label: '玉泉区',
              value: 2
            },
            {
              label: '郊  区',
              value: 3
            },
            {
              label: '土默特左旗',
              value: 4
            },
            {
              label: '托克托县',
              value: 5
            },
            {
              label: '和林格尔县',
              value: 6
            },
            {
              label: '清水河县',
              value: 7
            },
            {
              label: '武川县',
              value: 8
            }
          ]
        },
        {
          label: '包头市',
          value: 1,
          children: [
            {
              label: '东河区',
              value: 0
            },
            {
              label: '昆都伦区',
              value: 1
            },
            {
              label: '青山区',
              value: 2
            },
            {
              label: '石拐矿区',
              value: 3
            },
            {
              label: '白云矿区',
              value: 4
            },
            {
              label: '郊  区',
              value: 5
            },
            {
              label: '土默特右旗',
              value: 6
            },
            {
              label: '固阳县',
              value: 7
            },
            {
              label: '达尔罕茂明安联合旗',
              value: 8
            }
          ]
        },
        {
          label: '乌海市',
          value: 2,
          children: [
            {
              label: '海勃湾区',
              value: 0
            },
            {
              label: '海南区',
              value: 1
            },
            {
              label: '乌达区',
              value: 2
            }
          ]
        },
        {
          label: '赤峰市',
          value: 3,
          children: [
            {
              label: '红山区',
              value: 0
            },
            {
              label: '元宝山区',
              value: 1
            },
            {
              label: '松山区',
              value: 2
            },
            {
              label: '阿鲁科尔沁旗',
              value: 3
            },
            {
              label: '巴林左旗',
              value: 4
            },
            {
              label: '巴林右旗',
              value: 5
            },
            {
              label: '林西县',
              value: 6
            },
            {
              label: '克什克腾旗',
              value: 7
            },
            {
              label: '翁牛特旗',
              value: 8
            },
            {
              label: '喀喇沁旗',
              value: 9
            },
            {
              label: '宁城县',
              value: 10
            },
            {
              label: '敖汉旗',
              value: 11
            }
          ]
        },
        {
          label: '呼伦贝尔市',
          value: 4,
          children: [
            {
              label: '海拉尔市',
              value: 0
            },
            {
              label: '满洲里市',
              value: 1
            },
            {
              label: '扎兰屯市',
              value: 2
            },
            {
              label: '牙克石市',
              value: 3
            },
            {
              label: '根河市',
              value: 4
            },
            {
              label: '额尔古纳市',
              value: 5
            },
            {
              label: '阿荣旗',
              value: 6
            },
            {
              label: '莫力达瓦达斡尔族自治旗',
              value: 7
            },
            {
              label: '鄂伦春自治旗',
              value: 8
            },
            {
              label: '鄂温克族自治旗',
              value: 9
            },
            {
              label: '新巴尔虎右旗',
              value: 10
            },
            {
              label: '新巴尔虎左旗',
              value: 11
            },
            {
              label: '陈巴尔虎旗',
              value: 12
            }
          ]
        },
        {
          label: '兴安盟',
          value: 5,
          children: [
            {
              label: '乌兰浩特市',
              value: 0
            },
            {
              label: '阿尔山市',
              value: 1
            },
            {
              label: '科尔沁右翼前旗',
              value: 2
            },
            {
              label: '科尔沁右翼中旗',
              value: 3
            },
            {
              label: '扎赉特旗',
              value: 4
            },
            {
              label: '突泉县',
              value: 5
            }
          ]
        },
        {
          label: '通辽市',
          value: 6,
          children: [
            {
              label: '科尔沁区',
              value: 0
            },
            {
              label: '霍林郭勒市',
              value: 1
            },
            {
              label: '科尔沁左翼中旗',
              value: 2
            },
            {
              label: '科尔沁左翼后旗',
              value: 3
            },
            {
              label: '开鲁县',
              value: 4
            },
            {
              label: '库伦旗',
              value: 5
            },
            {
              label: '奈曼旗',
              value: 6
            },
            {
              label: '扎鲁特旗',
              value: 7
            }
          ]
        },
        {
          label: '锡林郭勒盟',
          value: 7,
          children: [
            {
              label: '二连浩特市',
              value: 0
            },
            {
              label: '锡林浩特市',
              value: 1
            },
            {
              label: '阿巴嘎旗',
              value: 2
            },
            {
              label: '苏尼特左旗',
              value: 3
            },
            {
              label: '苏尼特右旗',
              value: 4
            },
            {
              label: '东乌珠穆沁旗',
              value: 5
            },
            {
              label: '西乌珠穆沁旗',
              value: 6
            },
            {
              label: '太仆寺旗',
              value: 7
            },
            {
              label: '镶黄旗',
              value: 8
            },
            {
              label: '正镶白旗',
              value: 9
            },
            {
              label: '正蓝旗',
              value: 10
            },
            {
              label: '多伦县',
              value: 11
            }
          ]
        },
        {
          label: '乌兰察布盟',
          value: 8,
          children: [
            {
              label: '集宁市',
              value: 0
            },
            {
              label: '丰镇市',
              value: 1
            },
            {
              label: '卓资县',
              value: 2
            },
            {
              label: '化德县',
              value: 3
            },
            {
              label: '商都县',
              value: 4
            },
            {
              label: '兴和县',
              value: 5
            },
            {
              label: '凉城县',
              value: 6
            },
            {
              label: '察哈尔右翼前旗',
              value: 7
            },
            {
              label: '察哈尔右翼中旗',
              value: 8
            },
            {
              label: '察哈尔右翼后旗',
              value: 9
            },
            {
              label: '四子王旗',
              value: 10
            }
          ]
        },
        {
          label: '伊克昭盟',
          value: 9,
          children: [
            {
              label: '东胜市',
              value: 0
            },
            {
              label: '达拉特旗',
              value: 1
            },
            {
              label: '准格尔旗',
              value: 2
            },
            {
              label: '鄂托克前旗',
              value: 3
            },
            {
              label: '鄂托克旗',
              value: 4
            },
            {
              label: '杭锦旗',
              value: 5
            },
            {
              label: '乌审旗',
              value: 6
            },
            {
              label: '伊金霍洛旗',
              value: 7
            }
          ]
        },
        {
          label: '巴彦淖尔盟',
          value: 10,
          children: [
            {
              label: '临河市',
              value: 0
            },
            {
              label: '五原县',
              value: 1
            },
            {
              label: '磴口县',
              value: 2
            },
            {
              label: '乌拉特前旗',
              value: 3
            },
            {
              label: '乌拉特中旗',
              value: 4
            },
            {
              label: '乌拉特后旗',
              value: 5
            },
            {
              label: '杭锦后旗',
              value: 6
            }
          ]
        },
        {
          label: '阿拉善盟',
          value: 11,
          children: [
            {
              label: '阿拉善左旗',
              value: 0
            },
            {
              label: '阿拉善右旗',
              value: 1
            },
            {
              label: '额济纳旗',
              value: 2
            }
          ]
        }
      ]
    },
    {
      label: '辽宁省',
      value: 5,
      children: [
        {
          label: '沈阳市',
          value: 0,
          children: [
            {
              label: '沈河区',
              value: 0
            },
            {
              label: '皇姑区',
              value: 1
            },
            {
              label: '和平区',
              value: 2
            },
            {
              label: '大东区',
              value: 3
            },
            {
              label: '铁西区',
              value: 4
            },
            {
              label: '苏家屯区',
              value: 5
            },
            {
              label: '东陵区',
              value: 6
            },
            {
              label: '于洪区',
              value: 7
            },
            {
              label: '新民市',
              value: 8
            },
            {
              label: '法库县',
              value: 9
            },
            {
              label: '辽中县',
              value: 10
            },
            {
              label: '康平县',
              value: 11
            },
            {
              label: '新城子区',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '大连市',
          value: 1,
          children: [
            {
              label: '西岗区',
              value: 0
            },
            {
              label: '中山区',
              value: 1
            },
            {
              label: '沙河口区',
              value: 2
            },
            {
              label: '甘井子区',
              value: 3
            },
            {
              label: '旅顺口区',
              value: 4
            },
            {
              label: '金州区',
              value: 5
            },
            {
              label: '瓦房店市',
              value: 6
            },
            {
              label: '普兰店市',
              value: 7
            },
            {
              label: '庄河市',
              value: 8
            },
            {
              label: '长海县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '鞍山市',
          value: 2,
          children: [
            {
              label: '铁东区',
              value: 0
            },
            {
              label: '铁西区',
              value: 1
            },
            {
              label: '立山区',
              value: 2
            },
            {
              label: '千山区',
              value: 3
            },
            {
              label: '海城市',
              value: 4
            },
            {
              label: '台安县',
              value: 5
            },
            {
              label: '岫岩满族自治县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '抚顺市',
          value: 3,
          children: [
            {
              label: '顺城区',
              value: 0
            },
            {
              label: '新抚区',
              value: 1
            },
            {
              label: '东洲区',
              value: 2
            },
            {
              label: '望花区',
              value: 3
            },
            {
              label: '抚顺县',
              value: 4
            },
            {
              label: '清原满族自治县',
              value: 5
            },
            {
              label: '新宾满族自治县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '本溪市',
          value: 4,
          children: [
            {
              label: '平山区',
              value: 0
            },
            {
              label: '明山区',
              value: 1
            },
            {
              label: '溪湖区',
              value: 2
            },
            {
              label: '南芬区',
              value: 3
            },
            {
              label: '本溪满族自治县',
              value: 4
            },
            {
              label: '桓仁满族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '丹东市',
          value: 5,
          children: [
            {
              label: '振兴区',
              value: 0
            },
            {
              label: '元宝区',
              value: 1
            },
            {
              label: '振安区',
              value: 2
            },
            {
              label: '东港市',
              value: 3
            },
            {
              label: '凤城市',
              value: 4
            },
            {
              label: '宽甸满族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '锦州市',
          value: 6,
          children: [
            {
              label: '太和区',
              value: 0
            },
            {
              label: '古塔区',
              value: 1
            },
            {
              label: '凌河区',
              value: 2
            },
            {
              label: '凌海市',
              value: 3
            },
            {
              label: '黑山县',
              value: 4
            },
            {
              label: '义县',
              value: 5
            },
            {
              label: '北宁市',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '营口市',
          value: 7,
          children: [
            {
              label: '站前区',
              value: 0
            },
            {
              label: '西市区',
              value: 1
            },
            {
              label: '鲅鱼圈区',
              value: 2
            },
            {
              label: '老边区',
              value: 3
            },
            {
              label: '大石桥市',
              value: 4
            },
            {
              label: '盖州市',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '阜新市',
          value: 8,
          children: [
            {
              label: '海州区',
              value: 0
            },
            {
              label: '新邱区',
              value: 1
            },
            {
              label: '太平区',
              value: 2
            },
            {
              label: '清河门区',
              value: 3
            },
            {
              label: '细河区',
              value: 4
            },
            {
              label: '彰武县',
              value: 5
            },
            {
              label: '阜新蒙古族自治县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '辽阳市',
          value: 9,
          children: [
            {
              label: '白塔区',
              value: 0
            },
            {
              label: '文圣区',
              value: 1
            },
            {
              label: '宏伟区',
              value: 2
            },
            {
              label: '太子河区',
              value: 3
            },
            {
              label: '弓长岭区',
              value: 4
            },
            {
              label: '灯塔市',
              value: 5
            },
            {
              label: '辽阳县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '盘锦',
          value: 10,
          children: [
            {
              label: '双台子区',
              value: 0
            },
            {
              label: '兴隆台区',
              value: 1
            },
            {
              label: '盘山县',
              value: 2
            },
            {
              label: '大洼县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '铁岭市',
          value: 11,
          children: [
            {
              label: '银州区',
              value: 0
            },
            {
              label: '清河区',
              value: 1
            },
            {
              label: '调兵山市',
              value: 2
            },
            {
              label: '开原市',
              value: 3
            },
            {
              label: '铁岭县',
              value: 4
            },
            {
              label: '昌图县',
              value: 5
            },
            {
              label: '西丰县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '朝阳市',
          value: 12,
          children: [
            {
              label: '双塔区',
              value: 0
            },
            {
              label: '龙城区',
              value: 1
            },
            {
              label: '凌源市',
              value: 2
            },
            {
              label: '北票市',
              value: 3
            },
            {
              label: '朝阳县',
              value: 4
            },
            {
              label: '建平县',
              value: 5
            },
            {
              label: '喀喇沁左翼蒙古族自治县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '葫芦岛市',
          value: 13,
          children: [
            {
              label: '龙港区',
              value: 0
            },
            {
              label: '南票区',
              value: 1
            },
            {
              label: '连山区',
              value: 2
            },
            {
              label: '兴城市',
              value: 3
            },
            {
              label: '绥中县',
              value: 4
            },
            {
              label: '建昌县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '其他',
          value: 14,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '吉林省',
      value: 6,
      children: [
        {
          label: '长春市',
          value: 0,
          children: [
            {
              label: '朝阳区',
              value: 0
            },
            {
              label: '宽城区',
              value: 1
            },
            {
              label: '二道区',
              value: 2
            },
            {
              label: '南关区',
              value: 3
            },
            {
              label: '绿园区',
              value: 4
            },
            {
              label: '双阳区',
              value: 5
            },
            {
              label: '九台市',
              value: 6
            },
            {
              label: '榆树市',
              value: 7
            },
            {
              label: '德惠市',
              value: 8
            },
            {
              label: '农安县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '吉林市',
          value: 1,
          children: [
            {
              label: '船营区',
              value: 0
            },
            {
              label: '昌邑区',
              value: 1
            },
            {
              label: '龙潭区',
              value: 2
            },
            {
              label: '丰满区',
              value: 3
            },
            {
              label: '舒兰市',
              value: 4
            },
            {
              label: '桦甸市',
              value: 5
            },
            {
              label: '蛟河市',
              value: 6
            },
            {
              label: '磐石市',
              value: 7
            },
            {
              label: '永吉县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '四平',
          value: 2,
          children: [
            {
              label: '铁西区',
              value: 0
            },
            {
              label: '铁东区',
              value: 1
            },
            {
              label: '公主岭市',
              value: 2
            },
            {
              label: '双辽市',
              value: 3
            },
            {
              label: '梨树县',
              value: 4
            },
            {
              label: '伊通满族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '辽源市',
          value: 3,
          children: [
            {
              label: '龙山区',
              value: 0
            },
            {
              label: '西安区',
              value: 1
            },
            {
              label: '东辽县',
              value: 2
            },
            {
              label: '东丰县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '通化市',
          value: 4,
          children: [
            {
              label: '东昌区',
              value: 0
            },
            {
              label: '二道江区',
              value: 1
            },
            {
              label: '梅河口市',
              value: 2
            },
            {
              label: '集安市',
              value: 3
            },
            {
              label: '通化县',
              value: 4
            },
            {
              label: '辉南县',
              value: 5
            },
            {
              label: '柳河县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '白山市',
          value: 5,
          children: [
            {
              label: '八道江区',
              value: 0
            },
            {
              label: '江源区',
              value: 1
            },
            {
              label: '临江市',
              value: 2
            },
            {
              label: '靖宇县',
              value: 3
            },
            {
              label: '抚松县',
              value: 4
            },
            {
              label: '长白朝鲜族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '松原市',
          value: 6,
          children: [
            {
              label: '宁江区',
              value: 0
            },
            {
              label: '乾安县',
              value: 1
            },
            {
              label: '长岭县',
              value: 2
            },
            {
              label: '扶余县',
              value: 3
            },
            {
              label: '前郭尔罗斯蒙古族自治县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '白城市',
          value: 7,
          children: [
            {
              label: '洮北区',
              value: 0
            },
            {
              label: '大安市',
              value: 1
            },
            {
              label: '洮南市',
              value: 2
            },
            {
              label: '镇赉县',
              value: 3
            },
            {
              label: '通榆县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '延边朝鲜族自治州',
          value: 8,
          children: [
            {
              label: '延吉市',
              value: 0
            },
            {
              label: '图们市',
              value: 1
            },
            {
              label: '敦化市',
              value: 2
            },
            {
              label: '龙井市',
              value: 3
            },
            {
              label: '珲春市',
              value: 4
            },
            {
              label: '和龙市',
              value: 5
            },
            {
              label: '安图县',
              value: 6
            },
            {
              label: '汪清县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '其他',
          value: 9,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '黑龙江省',
      value: 7,
      children: [
        {
          label: '哈尔滨市',
          value: 0,
          children: [
            {
              label: '松北区',
              value: 0
            },
            {
              label: '道里区',
              value: 1
            },
            {
              label: '南岗区',
              value: 2
            },
            {
              label: '平房区',
              value: 3
            },
            {
              label: '香坊区',
              value: 4
            },
            {
              label: '道外区',
              value: 5
            },
            {
              label: '呼兰区',
              value: 6
            },
            {
              label: '阿城区',
              value: 7
            },
            {
              label: '双城市',
              value: 8
            },
            {
              label: '尚志市',
              value: 9
            },
            {
              label: '五常市',
              value: 10
            },
            {
              label: '宾县',
              value: 11
            },
            {
              label: '方正县',
              value: 12
            },
            {
              label: '通河县',
              value: 13
            },
            {
              label: '巴彦县',
              value: 14
            },
            {
              label: '延寿县',
              value: 15
            },
            {
              label: '木兰县',
              value: 16
            },
            {
              label: '依兰县',
              value: 17
            },
            {
              label: '其他',
              value: 18
            }
          ]
        },
        {
          label: '齐齐哈尔市',
          value: 1,
          children: [
            {
              label: '龙沙区',
              value: 0
            },
            {
              label: '昂昂溪区',
              value: 1
            },
            {
              label: '铁锋区',
              value: 2
            },
            {
              label: '建华区',
              value: 3
            },
            {
              label: '富拉尔基区',
              value: 4
            },
            {
              label: '碾子山区',
              value: 5
            },
            {
              label: '梅里斯达斡尔族区',
              value: 6
            },
            {
              label: '讷河市',
              value: 7
            },
            {
              label: '富裕县',
              value: 8
            },
            {
              label: '拜泉县',
              value: 9
            },
            {
              label: '甘南县',
              value: 10
            },
            {
              label: '依安县',
              value: 11
            },
            {
              label: '克山县',
              value: 12
            },
            {
              label: '泰来县',
              value: 13
            },
            {
              label: '克东县',
              value: 14
            },
            {
              label: '龙江县',
              value: 15
            },
            {
              label: '其他',
              value: 16
            }
          ]
        },
        {
          label: '鹤岗市',
          value: 2,
          children: [
            {
              label: '兴山区',
              value: 0
            },
            {
              label: '工农区',
              value: 1
            },
            {
              label: '南山区',
              value: 2
            },
            {
              label: '兴安区',
              value: 3
            },
            {
              label: '向阳区',
              value: 4
            },
            {
              label: '东山区',
              value: 5
            },
            {
              label: '萝北县',
              value: 6
            },
            {
              label: '绥滨县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '双鸭山',
          value: 3,
          children: [
            {
              label: '尖山区',
              value: 0
            },
            {
              label: '岭东区',
              value: 1
            },
            {
              label: '四方台区',
              value: 2
            },
            {
              label: '宝山区',
              value: 3
            },
            {
              label: '集贤县',
              value: 4
            },
            {
              label: '宝清县',
              value: 5
            },
            {
              label: '友谊县',
              value: 6
            },
            {
              label: '饶河县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '鸡西市',
          value: 4,
          children: [
            {
              label: '鸡冠区',
              value: 0
            },
            {
              label: '恒山区',
              value: 1
            },
            {
              label: '城子河区',
              value: 2
            },
            {
              label: '滴道区',
              value: 3
            },
            {
              label: '梨树区',
              value: 4
            },
            {
              label: '麻山区',
              value: 5
            },
            {
              label: '密山市',
              value: 6
            },
            {
              label: '虎林市',
              value: 7
            },
            {
              label: '鸡东县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '大庆市',
          value: 5,
          children: [
            {
              label: '萨尔图区',
              value: 0
            },
            {
              label: '红岗区',
              value: 1
            },
            {
              label: '龙凤区',
              value: 2
            },
            {
              label: '让胡路区',
              value: 3
            },
            {
              label: '大同区',
              value: 4
            },
            {
              label: '林甸县',
              value: 5
            },
            {
              label: '肇州县',
              value: 6
            },
            {
              label: '肇源县',
              value: 7
            },
            {
              label: '杜尔伯特蒙古族自治县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '伊春市',
          value: 6,
          children: [
            {
              label: '伊春区',
              value: 0
            },
            {
              label: '带岭区',
              value: 1
            },
            {
              label: '南岔区',
              value: 2
            },
            {
              label: '金山屯区',
              value: 3
            },
            {
              label: '西林区',
              value: 4
            },
            {
              label: '美溪区',
              value: 5
            },
            {
              label: '乌马河区',
              value: 6
            },
            {
              label: '翠峦区',
              value: 7
            },
            {
              label: '友好区',
              value: 8
            },
            {
              label: '上甘岭区',
              value: 9
            },
            {
              label: '五营区',
              value: 10
            },
            {
              label: '红星区',
              value: 11
            },
            {
              label: '新青区',
              value: 12
            },
            {
              label: '汤旺河区',
              value: 13
            },
            {
              label: '乌伊岭区',
              value: 14
            },
            {
              label: '铁力市',
              value: 15
            },
            {
              label: '嘉荫县',
              value: 16
            },
            {
              label: '其他',
              value: 17
            }
          ]
        },
        {
          label: '牡丹江市',
          value: 7,
          children: [
            {
              label: '爱民区',
              value: 0
            },
            {
              label: '东安区',
              value: 1
            },
            {
              label: '阳明区',
              value: 2
            },
            {
              label: '西安区',
              value: 3
            },
            {
              label: '绥芬河市',
              value: 4
            },
            {
              label: '宁安市',
              value: 5
            },
            {
              label: '海林市',
              value: 6
            },
            {
              label: '穆棱市',
              value: 7
            },
            {
              label: '林口县',
              value: 8
            },
            {
              label: '东宁县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '佳木斯市',
          value: 8,
          children: [
            {
              label: '向阳区',
              value: 0
            },
            {
              label: '前进区',
              value: 1
            },
            {
              label: '东风区',
              value: 2
            },
            {
              label: '郊区',
              value: 3
            },
            {
              label: '同江市',
              value: 4
            },
            {
              label: '富锦市',
              value: 5
            },
            {
              label: '桦川县',
              value: 6
            },
            {
              label: '抚远县',
              value: 7
            },
            {
              label: '桦南县',
              value: 8
            },
            {
              label: '汤原县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '七台河市',
          value: 9,
          children: [
            {
              label: '桃山区',
              value: 0
            },
            {
              label: '新兴区',
              value: 1
            },
            {
              label: '茄子河区',
              value: 2
            },
            {
              label: '勃利县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '黑河市',
          value: 10,
          children: [
            {
              label: '爱辉区',
              value: 0
            },
            {
              label: '北安市',
              value: 1
            },
            {
              label: '五大连池市',
              value: 2
            },
            {
              label: '逊克县',
              value: 3
            },
            {
              label: '嫩江县',
              value: 4
            },
            {
              label: '孙吴县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '绥化市',
          value: 11,
          children: [
            {
              label: '北林区',
              value: 0
            },
            {
              label: '安达市',
              value: 1
            },
            {
              label: '肇东市',
              value: 2
            },
            {
              label: '海伦市',
              value: 3
            },
            {
              label: '绥棱县',
              value: 4
            },
            {
              label: '兰西县',
              value: 5
            },
            {
              label: '明水县',
              value: 6
            },
            {
              label: '青冈县',
              value: 7
            },
            {
              label: '庆安县',
              value: 8
            },
            {
              label: '望奎县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '大兴安岭地区',
          value: 12,
          children: [
            {
              label: '呼玛县',
              value: 0
            },
            {
              label: '塔河县',
              value: 1
            },
            {
              label: '漠河县',
              value: 2
            },
            {
              label: '大兴安岭辖区',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '其他',
          value: 13,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '上海市',
      value: 8,
      children: [
        {
          label: '上海市',
          value: 0,
          children: [
            {
              label: '黄浦区',
              value: 0
            },
            {
              label: '卢湾区',
              value: 1
            },
            {
              label: '徐汇区',
              value: 2
            },
            {
              label: '长宁区',
              value: 3
            },
            {
              label: '静安区',
              value: 4
            },
            {
              label: '普陀区',
              value: 5
            },
            {
              label: '闸北区',
              value: 6
            },
            {
              label: '虹口区',
              value: 7
            },
            {
              label: '杨浦区',
              value: 8
            },
            {
              label: '宝山区',
              value: 9
            },
            {
              label: '闵行区',
              value: 10
            },
            {
              label: '嘉定区',
              value: 11
            },
            {
              label: '松江区',
              value: 12
            },
            {
              label: '金山区',
              value: 13
            },
            {
              label: '青浦区',
              value: 14
            },
            {
              label: '南汇区',
              value: 15
            },
            {
              label: '奉贤区',
              value: 16
            },
            {
              label: '浦东新区',
              value: 17
            },
            {
              label: '崇明县',
              value: 18
            },
            {
              label: '其他',
              value: 19
            }
          ]
        }
      ]
    },
    {
      label: '江苏省',
      value: 9,
      children: [
        {
          label: '南京市',
          value: 0,
          children: [
            {
              label: '玄武区',
              value: 0
            },
            {
              label: '白下区',
              value: 1
            },
            {
              label: '秦淮区',
              value: 2
            },
            {
              label: '建邺区',
              value: 3
            },
            {
              label: '鼓楼区',
              value: 4
            },
            {
              label: '下关区',
              value: 5
            },
            {
              label: '栖霞区',
              value: 6
            },
            {
              label: '雨花台区',
              value: 7
            },
            {
              label: '浦口区',
              value: 8
            },
            {
              label: '江宁区',
              value: 9
            },
            {
              label: '六合区',
              value: 10
            },
            {
              label: '溧水县',
              value: 11
            },
            {
              label: '高淳县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '苏州市',
          value: 1,
          children: [
            {
              label: '金阊区',
              value: 0
            },
            {
              label: '平江区',
              value: 1
            },
            {
              label: '沧浪区',
              value: 2
            },
            {
              label: '虎丘区',
              value: 3
            },
            {
              label: '吴中区',
              value: 4
            },
            {
              label: '相城区',
              value: 5
            },
            {
              label: '常熟市',
              value: 6
            },
            {
              label: '张家港市',
              value: 7
            },
            {
              label: '昆山市',
              value: 8
            },
            {
              label: '吴江市',
              value: 9
            },
            {
              label: '太仓市',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '无锡市',
          value: 2,
          children: [
            {
              label: '崇安区',
              value: 0
            },
            {
              label: '南长区',
              value: 1
            },
            {
              label: '北塘区',
              value: 2
            },
            {
              label: '滨湖区',
              value: 3
            },
            {
              label: '锡山区',
              value: 4
            },
            {
              label: '惠山区',
              value: 5
            },
            {
              label: '江阴市',
              value: 6
            },
            {
              label: '宜兴市',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '常州市',
          value: 3,
          children: [
            {
              label: '钟楼区',
              value: 0
            },
            {
              label: '天宁区',
              value: 1
            },
            {
              label: '戚墅堰区',
              value: 2
            },
            {
              label: '新北区',
              value: 3
            },
            {
              label: '武进区',
              value: 4
            },
            {
              label: '金坛市',
              value: 5
            },
            {
              label: '溧阳市',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '镇江市',
          value: 4,
          children: [
            {
              label: '京口区',
              value: 0
            },
            {
              label: '润州区',
              value: 1
            },
            {
              label: '丹徒区',
              value: 2
            },
            {
              label: '丹阳市',
              value: 3
            },
            {
              label: '扬中市',
              value: 4
            },
            {
              label: '句容市',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '南通市',
          value: 5,
          children: [
            {
              label: '崇川区',
              value: 0
            },
            {
              label: '港闸区',
              value: 1
            },
            {
              label: '通州市',
              value: 2
            },
            {
              label: '如皋市',
              value: 3
            },
            {
              label: '海门市',
              value: 4
            },
            {
              label: '启东市',
              value: 5
            },
            {
              label: '海安县',
              value: 6
            },
            {
              label: '如东县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '泰州市',
          value: 6,
          children: [
            {
              label: '海陵区',
              value: 0
            },
            {
              label: '高港区',
              value: 1
            },
            {
              label: '姜堰市',
              value: 2
            },
            {
              label: '泰兴市',
              value: 3
            },
            {
              label: '靖江市',
              value: 4
            },
            {
              label: '兴化市',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '扬州市',
          value: 7,
          children: [
            {
              label: '广陵区',
              value: 0
            },
            {
              label: '维扬区',
              value: 1
            },
            {
              label: '邗江区',
              value: 2
            },
            {
              label: '江都市',
              value: 3
            },
            {
              label: '仪征市',
              value: 4
            },
            {
              label: '高邮市',
              value: 5
            },
            {
              label: '宝应县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '盐城市',
          value: 8,
          children: [
            {
              label: '亭湖区',
              value: 0
            },
            {
              label: '盐都区',
              value: 1
            },
            {
              label: '大丰市',
              value: 2
            },
            {
              label: '东台市',
              value: 3
            },
            {
              label: '建湖县',
              value: 4
            },
            {
              label: '射阳县',
              value: 5
            },
            {
              label: '阜宁县',
              value: 6
            },
            {
              label: '滨海县',
              value: 7
            },
            {
              label: '响水县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '连云港市',
          value: 9,
          children: [
            {
              label: '新浦区',
              value: 0
            },
            {
              label: '海州区',
              value: 1
            },
            {
              label: '连云区',
              value: 2
            },
            {
              label: '东海县',
              value: 3
            },
            {
              label: '灌云县',
              value: 4
            },
            {
              label: '赣榆县',
              value: 5
            },
            {
              label: '灌南县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '徐州市',
          value: 10,
          children: [
            {
              label: '云龙区',
              value: 0
            },
            {
              label: '鼓楼区',
              value: 1
            },
            {
              label: '九里区',
              value: 2
            },
            {
              label: '泉山区',
              value: 3
            },
            {
              label: '贾汪区',
              value: 4
            },
            {
              label: '邳州市',
              value: 5
            },
            {
              label: '新沂市',
              value: 6
            },
            {
              label: '铜山县',
              value: 7
            },
            {
              label: '睢宁县',
              value: 8
            },
            {
              label: '沛县',
              value: 9
            },
            {
              label: '丰县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '淮安市',
          value: 11,
          children: [
            {
              label: '清河区',
              value: 0
            },
            {
              label: '清浦区',
              value: 1
            },
            {
              label: '楚州区',
              value: 2
            },
            {
              label: '淮阴区',
              value: 3
            },
            {
              label: '涟水县',
              value: 4
            },
            {
              label: '洪泽县',
              value: 5
            },
            {
              label: '金湖县',
              value: 6
            },
            {
              label: '盱眙县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '宿迁市',
          value: 12,
          children: [
            {
              label: '宿城区',
              value: 0
            },
            {
              label: '宿豫区',
              value: 1
            },
            {
              label: '沭阳县',
              value: 2
            },
            {
              label: '泗阳县',
              value: 3
            },
            {
              label: '泗洪县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '其他',
          value: 13,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '浙江省',
      value: 10,
      children: [
        {
          label: '杭州市',
          value: 0,
          children: [
            {
              label: '拱墅区',
              value: 0
            },
            {
              label: '西湖区',
              value: 1
            },
            {
              label: '上城区',
              value: 2
            },
            {
              label: '下城区',
              value: 3
            },
            {
              label: '江干区',
              value: 4
            },
            {
              label: '滨江区',
              value: 5
            },
            {
              label: '余杭区',
              value: 6
            },
            {
              label: '萧山区',
              value: 7
            },
            {
              label: '建德市',
              value: 8
            },
            {
              label: '富阳市',
              value: 9
            },
            {
              label: '临安市',
              value: 10
            },
            {
              label: '桐庐县',
              value: 11
            },
            {
              label: '淳安县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '宁波市',
          value: 1,
          children: [
            {
              label: '海曙区',
              value: 0
            },
            {
              label: '江东区',
              value: 1
            },
            {
              label: '江北区',
              value: 2
            },
            {
              label: '镇海区',
              value: 3
            },
            {
              label: '北仑区',
              value: 4
            },
            {
              label: '鄞州区',
              value: 5
            },
            {
              label: '余姚市',
              value: 6
            },
            {
              label: '慈溪市',
              value: 7
            },
            {
              label: '奉化市',
              value: 8
            },
            {
              label: '宁海县',
              value: 9
            },
            {
              label: '象山县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '温州市',
          value: 2,
          children: [
            {
              label: '鹿城区',
              value: 0
            },
            {
              label: '龙湾区',
              value: 1
            },
            {
              label: '瓯海区',
              value: 2
            },
            {
              label: '瑞安市',
              value: 3
            },
            {
              label: '乐清市',
              value: 4
            },
            {
              label: '永嘉县',
              value: 5
            },
            {
              label: '洞头县',
              value: 6
            },
            {
              label: '平阳县',
              value: 7
            },
            {
              label: '苍南县',
              value: 8
            },
            {
              label: '文成县',
              value: 9
            },
            {
              label: '泰顺县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '嘉兴市',
          value: 3,
          children: [
            {
              label: '秀城区',
              value: 0
            },
            {
              label: '秀洲区',
              value: 1
            },
            {
              label: '海宁市',
              value: 2
            },
            {
              label: '平湖市',
              value: 3
            },
            {
              label: '桐乡市',
              value: 4
            },
            {
              label: '嘉善县',
              value: 5
            },
            {
              label: '海盐县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '湖州市',
          value: 4,
          children: [
            {
              label: '吴兴区',
              value: 0
            },
            {
              label: '南浔区',
              value: 1
            },
            {
              label: '长兴县',
              value: 2
            },
            {
              label: '德清县',
              value: 3
            },
            {
              label: '安吉县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '绍兴市',
          value: 5,
          children: [
            {
              label: '越城区',
              value: 0
            },
            {
              label: '诸暨市',
              value: 1
            },
            {
              label: '上虞市',
              value: 2
            },
            {
              label: '嵊州市',
              value: 3
            },
            {
              label: '绍兴县',
              value: 4
            },
            {
              label: '新昌县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '金华市',
          value: 6,
          children: [
            {
              label: '婺城区',
              value: 0
            },
            {
              label: '金东区',
              value: 1
            },
            {
              label: '兰溪市',
              value: 2
            },
            {
              label: '义乌市',
              value: 3
            },
            {
              label: '东阳市',
              value: 4
            },
            {
              label: '永康市',
              value: 5
            },
            {
              label: '武义县',
              value: 6
            },
            {
              label: '浦江县',
              value: 7
            },
            {
              label: '磐安县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '衢州市',
          value: 7,
          children: [
            {
              label: '柯城区',
              value: 0
            },
            {
              label: '衢江区',
              value: 1
            },
            {
              label: '江山市',
              value: 2
            },
            {
              label: '龙游县',
              value: 3
            },
            {
              label: '常山县',
              value: 4
            },
            {
              label: '开化县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '舟山市',
          value: 8,
          children: [
            {
              label: '定海区',
              value: 0
            },
            {
              label: '普陀区',
              value: 1
            },
            {
              label: '岱山县',
              value: 2
            },
            {
              label: '嵊泗县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '台州市',
          value: 9,
          children: [
            {
              label: '椒江区',
              value: 0
            },
            {
              label: '黄岩区',
              value: 1
            },
            {
              label: '路桥区',
              value: 2
            },
            {
              label: '临海市',
              value: 3
            },
            {
              label: '温岭市',
              value: 4
            },
            {
              label: '玉环县',
              value: 5
            },
            {
              label: '天台县',
              value: 6
            },
            {
              label: '仙居县',
              value: 7
            },
            {
              label: '三门县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '丽水市',
          value: 10,
          children: [
            {
              label: '莲都区',
              value: 0
            },
            {
              label: '龙泉市',
              value: 1
            },
            {
              label: '缙云县',
              value: 2
            },
            {
              label: '青田县',
              value: 3
            },
            {
              label: '云和县',
              value: 4
            },
            {
              label: '遂昌县',
              value: 5
            },
            {
              label: '松阳县',
              value: 6
            },
            {
              label: '庆元县',
              value: 7
            },
            {
              label: '景宁畲族自治县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '其他市',
          value: 11,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '安徽省',
      value: 11,
      children: [
        {
          label: '合肥市',
          value: 0,
          children: [
            {
              label: '庐阳区',
              value: 0
            },
            {
              label: '瑶海区',
              value: 1
            },
            {
              label: '蜀山区',
              value: 2
            },
            {
              label: '包河区',
              value: 3
            },
            {
              label: '长丰县',
              value: 4
            },
            {
              label: '肥东县',
              value: 5
            },
            {
              label: '肥西县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '芜湖市',
          value: 1,
          children: [
            {
              label: '镜湖区',
              value: 0
            },
            {
              label: '弋江区',
              value: 1
            },
            {
              label: '鸠江区',
              value: 2
            },
            {
              label: '三山区',
              value: 3
            },
            {
              label: '芜湖县',
              value: 4
            },
            {
              label: '南陵县',
              value: 5
            },
            {
              label: '繁昌县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '蚌埠市',
          value: 2,
          children: [
            {
              label: '蚌山区',
              value: 0
            },
            {
              label: '龙子湖区',
              value: 1
            },
            {
              label: '禹会区',
              value: 2
            },
            {
              label: '淮上区',
              value: 3
            },
            {
              label: '怀远县',
              value: 4
            },
            {
              label: '固镇县',
              value: 5
            },
            {
              label: '五河县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '淮南市',
          value: 3,
          children: [
            {
              label: '田家庵区',
              value: 0
            },
            {
              label: '大通区',
              value: 1
            },
            {
              label: '谢家集区',
              value: 2
            },
            {
              label: '八公山区',
              value: 3
            },
            {
              label: '潘集区',
              value: 4
            },
            {
              label: '凤台县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '马鞍山市',
          value: 4,
          children: [
            {
              label: '雨山区',
              value: 0
            },
            {
              label: '花山区',
              value: 1
            },
            {
              label: '金家庄区',
              value: 2
            },
            {
              label: '当涂县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '淮北市',
          value: 5,
          children: [
            {
              label: '相山区',
              value: 0
            },
            {
              label: '杜集区',
              value: 1
            },
            {
              label: '烈山区',
              value: 2
            },
            {
              label: '濉溪县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '铜陵市',
          value: 6,
          children: [
            {
              label: '铜官山区',
              value: 0
            },
            {
              label: '狮子山区',
              value: 1
            },
            {
              label: '郊区',
              value: 2
            },
            {
              label: '铜陵县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '安庆市',
          value: 7,
          children: [
            {
              label: '迎江区',
              value: 0
            },
            {
              label: '大观区',
              value: 1
            },
            {
              label: '宜秀区',
              value: 2
            },
            {
              label: '桐城市',
              value: 3
            },
            {
              label: '宿松县',
              value: 4
            },
            {
              label: '枞阳县',
              value: 5
            },
            {
              label: '太湖县',
              value: 6
            },
            {
              label: '怀宁县',
              value: 7
            },
            {
              label: '岳西县',
              value: 8
            },
            {
              label: '望江县',
              value: 9
            },
            {
              label: '潜山县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '黄山市',
          value: 8,
          children: [
            {
              label: '屯溪区',
              value: 0
            },
            {
              label: '黄山区',
              value: 1
            },
            {
              label: '徽州区',
              value: 2
            },
            {
              label: '休宁县',
              value: 3
            },
            {
              label: '歙县',
              value: 4
            },
            {
              label: '祁门县',
              value: 5
            },
            {
              label: '黟县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '滁州市',
          value: 9,
          children: [
            {
              label: '琅琊区',
              value: 0
            },
            {
              label: '南谯区',
              value: 1
            },
            {
              label: '天长市',
              value: 2
            },
            {
              label: '明光市',
              value: 3
            },
            {
              label: '全椒县',
              value: 4
            },
            {
              label: '来安县',
              value: 5
            },
            {
              label: '定远县',
              value: 6
            },
            {
              label: '凤阳县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '阜阳市',
          value: 10,
          children: [
            {
              label: '颍州区',
              value: 0
            },
            {
              label: '颍东区',
              value: 1
            },
            {
              label: '颍泉区',
              value: 2
            },
            {
              label: '界首市',
              value: 3
            },
            {
              label: '临泉县',
              value: 4
            },
            {
              label: '颍上县',
              value: 5
            },
            {
              label: '阜南县',
              value: 6
            },
            {
              label: '太和县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '宿州市',
          value: 11,
          children: [
            {
              label: '埇桥区',
              value: 0
            },
            {
              label: '萧县',
              value: 1
            },
            {
              label: '泗县',
              value: 2
            },
            {
              label: '砀山县',
              value: 3
            },
            {
              label: '灵璧县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '巢湖市',
          value: 12,
          children: [
            {
              label: '居巢区',
              value: 0
            },
            {
              label: '含山县',
              value: 1
            },
            {
              label: '无为县',
              value: 2
            },
            {
              label: '庐江县',
              value: 3
            },
            {
              label: '和县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '六安市',
          value: 13,
          children: [
            {
              label: '金安区',
              value: 0
            },
            {
              label: '裕安区',
              value: 1
            },
            {
              label: '寿县',
              value: 2
            },
            {
              label: '霍山县',
              value: 3
            },
            {
              label: '霍邱县',
              value: 4
            },
            {
              label: '舒城县',
              value: 5
            },
            {
              label: '金寨县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '亳州市',
          value: 14,
          children: [
            {
              label: '谯城区',
              value: 0
            },
            {
              label: '利辛县',
              value: 1
            },
            {
              label: '涡阳县',
              value: 2
            },
            {
              label: '蒙城县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '池州市',
          value: 15,
          children: [
            {
              label: '贵池区',
              value: 0
            },
            {
              label: '东至县',
              value: 1
            },
            {
              label: '石台县',
              value: 2
            },
            {
              label: '青阳县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '宣城市',
          value: 16,
          children: [
            {
              label: '宣州区',
              value: 0
            },
            {
              label: '宁国市',
              value: 1
            },
            {
              label: '广德县',
              value: 2
            },
            {
              label: '郎溪县',
              value: 3
            },
            {
              label: '泾县',
              value: 4
            },
            {
              label: '旌德县',
              value: 5
            },
            {
              label: '绩溪县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '其他市',
          value: 17,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '福建省',
      value: 12,
      children: [
        {
          label: '福州市',
          value: 0,
          children: [
            {
              label: '鼓楼区',
              value: 0
            },
            {
              label: '台江区',
              value: 1
            },
            {
              label: '仓山区',
              value: 2
            },
            {
              label: '马尾区',
              value: 3
            },
            {
              label: '晋安区',
              value: 4
            },
            {
              label: '福清市',
              value: 5
            },
            {
              label: '长乐市',
              value: 6
            },
            {
              label: '闽侯县',
              value: 7
            },
            {
              label: '闽清县',
              value: 8
            },
            {
              label: '永泰县',
              value: 9
            },
            {
              label: '连江县',
              value: 10
            },
            {
              label: '罗源县',
              value: 11
            },
            {
              label: '平潭县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '厦门市',
          value: 1,
          children: [
            {
              label: '思明区',
              value: 0
            },
            {
              label: '海沧区',
              value: 1
            },
            {
              label: '湖里区',
              value: 2
            },
            {
              label: '集美区',
              value: 3
            },
            {
              label: '同安区',
              value: 4
            },
            {
              label: '翔安区',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '莆田市',
          value: 2,
          children: [
            {
              label: '城厢区',
              value: 0
            },
            {
              label: '涵江区',
              value: 1
            },
            {
              label: '荔城区',
              value: 2
            },
            {
              label: '秀屿区',
              value: 3
            },
            {
              label: '仙游县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '三明市',
          value: 3,
          children: [
            {
              label: '梅列区',
              value: 0
            },
            {
              label: '三元区',
              value: 1
            },
            {
              label: '永安市',
              value: 2
            },
            {
              label: '明溪县',
              value: 3
            },
            {
              label: '将乐县',
              value: 4
            },
            {
              label: '大田县',
              value: 5
            },
            {
              label: '宁化县',
              value: 6
            },
            {
              label: '建宁县',
              value: 7
            },
            {
              label: '沙县',
              value: 8
            },
            {
              label: '尤溪县',
              value: 9
            },
            {
              label: '清流县',
              value: 10
            },
            {
              label: '泰宁县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '泉州市',
          value: 4,
          children: [
            {
              label: '鲤城区',
              value: 0
            },
            {
              label: '丰泽区',
              value: 1
            },
            {
              label: '洛江区',
              value: 2
            },
            {
              label: '泉港区',
              value: 3
            },
            {
              label: '石狮市',
              value: 4
            },
            {
              label: '晋江市',
              value: 5
            },
            {
              label: '南安市',
              value: 6
            },
            {
              label: '惠安县',
              value: 7
            },
            {
              label: '永春县',
              value: 8
            },
            {
              label: '安溪县',
              value: 9
            },
            {
              label: '德化县',
              value: 10
            },
            {
              label: '金门县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '漳州市',
          value: 5,
          children: [
            {
              label: '芗城区',
              value: 0
            },
            {
              label: '龙文区',
              value: 1
            },
            {
              label: '龙海市',
              value: 2
            },
            {
              label: '平和县',
              value: 3
            },
            {
              label: '南靖县',
              value: 4
            },
            {
              label: '诏安县',
              value: 5
            },
            {
              label: '漳浦县',
              value: 6
            },
            {
              label: '华安县',
              value: 7
            },
            {
              label: '东山县',
              value: 8
            },
            {
              label: '长泰县',
              value: 9
            },
            {
              label: '云霄县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '南平市',
          value: 6,
          children: [
            {
              label: '延平区',
              value: 0
            },
            {
              label: '建瓯市',
              value: 1
            },
            {
              label: '邵武市',
              value: 2
            },
            {
              label: '武夷山市',
              value: 3
            },
            {
              label: '建阳市',
              value: 4
            },
            {
              label: '松溪县',
              value: 5
            },
            {
              label: '光泽县',
              value: 6
            },
            {
              label: '顺昌县',
              value: 7
            },
            {
              label: '浦城县',
              value: 8
            },
            {
              label: '政和县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '龙岩市',
          value: 7,
          children: [
            {
              label: '新罗区',
              value: 0
            },
            {
              label: '漳平市',
              value: 1
            },
            {
              label: '长汀县',
              value: 2
            },
            {
              label: '武平县',
              value: 3
            },
            {
              label: '上杭县',
              value: 4
            },
            {
              label: '永定县',
              value: 5
            },
            {
              label: '连城县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '宁德市',
          value: 8,
          children: [
            {
              label: '蕉城区',
              value: 0
            },
            {
              label: '福安市',
              value: 1
            },
            {
              label: '福鼎市',
              value: 2
            },
            {
              label: '寿宁县',
              value: 3
            },
            {
              label: '霞浦县',
              value: 4
            },
            {
              label: '柘荣县',
              value: 5
            },
            {
              label: '屏南县',
              value: 6
            },
            {
              label: '古田县',
              value: 7
            },
            {
              label: '周宁县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '其他',
          value: 9,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '江西省',
      value: 13,
      children: [
        {
          label: '南昌市',
          value: 0,
          children: [
            {
              label: '东湖区',
              value: 0
            },
            {
              label: '西湖区',
              value: 1
            },
            {
              label: '青云谱区',
              value: 2
            },
            {
              label: '湾里区',
              value: 3
            },
            {
              label: '青山湖区',
              value: 4
            },
            {
              label: '新建县',
              value: 5
            },
            {
              label: '南昌县',
              value: 6
            },
            {
              label: '进贤县',
              value: 7
            },
            {
              label: '安义县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '景德镇市',
          value: 1,
          children: [
            {
              label: '珠山区',
              value: 0
            },
            {
              label: '昌江区',
              value: 1
            },
            {
              label: '乐平市',
              value: 2
            },
            {
              label: '浮梁县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '萍乡市',
          value: 2,
          children: [
            {
              label: '安源区',
              value: 0
            },
            {
              label: '湘东区',
              value: 1
            },
            {
              label: '莲花县',
              value: 2
            },
            {
              label: '上栗县',
              value: 3
            },
            {
              label: '芦溪县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '九江市',
          value: 3,
          children: [
            {
              label: '浔阳区',
              value: 0
            },
            {
              label: '庐山区',
              value: 1
            },
            {
              label: '瑞昌市',
              value: 2
            },
            {
              label: '九江县',
              value: 3
            },
            {
              label: '星子县',
              value: 4
            },
            {
              label: '武宁县',
              value: 5
            },
            {
              label: '彭泽县',
              value: 6
            },
            {
              label: '永修县',
              value: 7
            },
            {
              label: '修水县',
              value: 8
            },
            {
              label: '湖口县',
              value: 9
            },
            {
              label: '德安县',
              value: 10
            },
            {
              label: '都昌县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '新余市',
          value: 4,
          children: [
            {
              label: '渝水区',
              value: 0
            },
            {
              label: '分宜县',
              value: 1
            },
            {
              label: '其他',
              value: 2
            }
          ]
        },
        {
          label: '鹰潭市',
          value: 5,
          children: [
            {
              label: '月湖区',
              value: 0
            },
            {
              label: '贵溪市',
              value: 1
            },
            {
              label: '余江县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '赣州市',
          value: 6,
          children: [
            {
              label: '章贡区',
              value: 0
            },
            {
              label: '瑞金市',
              value: 1
            },
            {
              label: '南康市',
              value: 2
            },
            {
              label: '石城县',
              value: 3
            },
            {
              label: '安远县',
              value: 4
            },
            {
              label: '赣县',
              value: 5
            },
            {
              label: '宁都县',
              value: 6
            },
            {
              label: '寻乌县',
              value: 7
            },
            {
              label: '兴国县',
              value: 8
            },
            {
              label: '定南县',
              value: 9
            },
            {
              label: '上犹县',
              value: 10
            },
            {
              label: '于都县',
              value: 11
            },
            {
              label: '龙南县',
              value: 12
            },
            {
              label: '崇义县',
              value: 13
            },
            {
              label: '信丰县',
              value: 14
            },
            {
              label: '全南县',
              value: 15
            },
            {
              label: '大余县',
              value: 16
            },
            {
              label: '会昌县',
              value: 17
            },
            {
              label: '其他',
              value: 18
            }
          ]
        },
        {
          label: '吉安市',
          value: 7,
          children: [
            {
              label: '吉州区',
              value: 0
            },
            {
              label: '青原区',
              value: 1
            },
            {
              label: '井冈山市',
              value: 2
            },
            {
              label: '吉安县',
              value: 3
            },
            {
              label: '永丰县',
              value: 4
            },
            {
              label: '永新县',
              value: 5
            },
            {
              label: '新干县',
              value: 6
            },
            {
              label: '泰和县',
              value: 7
            },
            {
              label: '峡江县',
              value: 8
            },
            {
              label: '遂川县',
              value: 9
            },
            {
              label: '安福县',
              value: 10
            },
            {
              label: '吉水县',
              value: 11
            },
            {
              label: '万安县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '宜春市',
          value: 8,
          children: [
            {
              label: '袁州区',
              value: 0
            },
            {
              label: '丰城市',
              value: 1
            },
            {
              label: '樟树市',
              value: 2
            },
            {
              label: '高安市',
              value: 3
            },
            {
              label: '铜鼓县',
              value: 4
            },
            {
              label: '靖安县',
              value: 5
            },
            {
              label: '宜丰县',
              value: 6
            },
            {
              label: '奉新县',
              value: 7
            },
            {
              label: '万载县',
              value: 8
            },
            {
              label: '上高县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '抚州市',
          value: 9,
          children: [
            {
              label: '临川区',
              value: 0
            },
            {
              label: '南丰县',
              value: 1
            },
            {
              label: '乐安县',
              value: 2
            },
            {
              label: '金溪县',
              value: 3
            },
            {
              label: '南城县',
              value: 4
            },
            {
              label: '东乡县',
              value: 5
            },
            {
              label: '资溪县',
              value: 6
            },
            {
              label: '宜黄县',
              value: 7
            },
            {
              label: '广昌县',
              value: 8
            },
            {
              label: '黎川县',
              value: 9
            },
            {
              label: '崇仁县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '上饶市',
          value: 10,
          children: [
            {
              label: '信州区',
              value: 0
            },
            {
              label: '德兴市',
              value: 1
            },
            {
              label: '上饶县',
              value: 2
            },
            {
              label: '广丰县',
              value: 3
            },
            {
              label: '鄱阳县',
              value: 4
            },
            {
              label: '婺源县',
              value: 5
            },
            {
              label: '铅山县',
              value: 6
            },
            {
              label: '余干县',
              value: 7
            },
            {
              label: '横峰县',
              value: 8
            },
            {
              label: '弋阳县',
              value: 9
            },
            {
              label: '玉山县',
              value: 10
            },
            {
              label: '万年县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '其他',
          value: 11,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '山东省',
      value: 14,
      children: [
        {
          label: '济南市',
          value: 0,
          children: [
            {
              label: '市中区',
              value: 0
            },
            {
              label: '历下区',
              value: 1
            },
            {
              label: '天桥区',
              value: 2
            },
            {
              label: '槐荫区',
              value: 3
            },
            {
              label: '历城区',
              value: 4
            },
            {
              label: '长清区',
              value: 5
            },
            {
              label: '章丘市',
              value: 6
            },
            {
              label: '平阴县',
              value: 7
            },
            {
              label: '济阳县',
              value: 8
            },
            {
              label: '商河县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '青岛市',
          value: 1,
          children: [
            {
              label: '市南区',
              value: 0
            },
            {
              label: '市北区',
              value: 1
            },
            {
              label: '城阳区',
              value: 2
            },
            {
              label: '四方区',
              value: 3
            },
            {
              label: '李沧区',
              value: 4
            },
            {
              label: '黄岛区',
              value: 5
            },
            {
              label: '崂山区',
              value: 6
            },
            {
              label: '胶南市',
              value: 7
            },
            {
              label: '胶州市',
              value: 8
            },
            {
              label: '平度市',
              value: 9
            },
            {
              label: '莱西市',
              value: 10
            },
            {
              label: '即墨市',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '淄博市',
          value: 2,
          children: [
            {
              label: '张店区',
              value: 0
            },
            {
              label: '临淄区',
              value: 1
            },
            {
              label: '淄川区',
              value: 2
            },
            {
              label: '博山区',
              value: 3
            },
            {
              label: '周村区',
              value: 4
            },
            {
              label: '桓台县',
              value: 5
            },
            {
              label: '高青县',
              value: 6
            },
            {
              label: '沂源县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '枣庄市',
          value: 3,
          children: [
            {
              label: '市中区',
              value: 0
            },
            {
              label: '山亭区',
              value: 1
            },
            {
              label: '峄城区',
              value: 2
            },
            {
              label: '台儿庄区',
              value: 3
            },
            {
              label: '薛城区',
              value: 4
            },
            {
              label: '滕州市',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '东营市',
          value: 4,
          children: [
            {
              label: '东营区',
              value: 0
            },
            {
              label: '河口区',
              value: 1
            },
            {
              label: '垦利县',
              value: 2
            },
            {
              label: '广饶县',
              value: 3
            },
            {
              label: '利津县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '烟台市',
          value: 5,
          children: [
            {
              label: '芝罘区',
              value: 0
            },
            {
              label: '福山区',
              value: 1
            },
            {
              label: '牟平区',
              value: 2
            },
            {
              label: '莱山区',
              value: 3
            },
            {
              label: '龙口市',
              value: 4
            },
            {
              label: '莱阳市',
              value: 5
            },
            {
              label: '莱州市',
              value: 6
            },
            {
              label: '招远市',
              value: 7
            },
            {
              label: '蓬莱市',
              value: 8
            },
            {
              label: '栖霞市',
              value: 9
            },
            {
              label: '海阳市',
              value: 10
            },
            {
              label: '长岛县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '潍坊市',
          value: 6,
          children: [
            {
              label: '潍城区',
              value: 0
            },
            {
              label: '寒亭区',
              value: 1
            },
            {
              label: '坊子区',
              value: 2
            },
            {
              label: '奎文区',
              value: 3
            },
            {
              label: '青州市',
              value: 4
            },
            {
              label: '诸城市',
              value: 5
            },
            {
              label: '寿光市',
              value: 6
            },
            {
              label: '安丘市',
              value: 7
            },
            {
              label: '高密市',
              value: 8
            },
            {
              label: '昌邑市',
              value: 9
            },
            {
              label: '昌乐县',
              value: 10
            },
            {
              label: '临朐县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '济宁市',
          value: 7,
          children: [
            {
              label: '市中区',
              value: 0
            },
            {
              label: '任城区',
              value: 1
            },
            {
              label: '曲阜市',
              value: 2
            },
            {
              label: '兖州市',
              value: 3
            },
            {
              label: '邹城市',
              value: 4
            },
            {
              label: '鱼台县',
              value: 5
            },
            {
              label: '金乡县',
              value: 6
            },
            {
              label: '嘉祥县',
              value: 7
            },
            {
              label: '微山县',
              value: 8
            },
            {
              label: '汶上县',
              value: 9
            },
            {
              label: '泗水县',
              value: 10
            },
            {
              label: '梁山县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '泰安市',
          value: 8,
          children: [
            {
              label: '泰山区',
              value: 0
            },
            {
              label: '岱岳区',
              value: 1
            },
            {
              label: '新泰市',
              value: 2
            },
            {
              label: '肥城市',
              value: 3
            },
            {
              label: '宁阳县',
              value: 4
            },
            {
              label: '东平县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '威海市',
          value: 9,
          children: [
            {
              label: '环翠区',
              value: 0
            },
            {
              label: '乳山市',
              value: 1
            },
            {
              label: '文登市',
              value: 2
            },
            {
              label: '荣成市',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '日照市',
          value: 10,
          children: [
            {
              label: '东港区',
              value: 0
            },
            {
              label: '岚山区',
              value: 1
            },
            {
              label: '五莲县',
              value: 2
            },
            {
              label: '莒县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '莱芜市',
          value: 11,
          children: [
            {
              label: '莱城区',
              value: 0
            },
            {
              label: '钢城区',
              value: 1
            },
            {
              label: '其他',
              value: 2
            }
          ]
        },
        {
          label: '临沂市',
          value: 12,
          children: [
            {
              label: '兰山区',
              value: 0
            },
            {
              label: '罗庄区',
              value: 1
            },
            {
              label: '河东区',
              value: 2
            },
            {
              label: '沂南县',
              value: 3
            },
            {
              label: '郯城县',
              value: 4
            },
            {
              label: '沂水县',
              value: 5
            },
            {
              label: '苍山县',
              value: 6
            },
            {
              label: '费县',
              value: 7
            },
            {
              label: '平邑县',
              value: 8
            },
            {
              label: '莒南县',
              value: 9
            },
            {
              label: '蒙阴县',
              value: 10
            },
            {
              label: '临沭县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '德州市',
          value: 13,
          children: [
            {
              label: '德城区',
              value: 0
            },
            {
              label: '乐陵市',
              value: 1
            },
            {
              label: '禹城市',
              value: 2
            },
            {
              label: '陵县',
              value: 3
            },
            {
              label: '宁津县',
              value: 4
            },
            {
              label: '齐河县',
              value: 5
            },
            {
              label: '武城县',
              value: 6
            },
            {
              label: '庆云县',
              value: 7
            },
            {
              label: '平原县',
              value: 8
            },
            {
              label: '夏津县',
              value: 9
            },
            {
              label: '临邑县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '聊城市',
          value: 14,
          children: [
            {
              label: '东昌府区',
              value: 0
            },
            {
              label: '临清市',
              value: 1
            },
            {
              label: '高唐县',
              value: 2
            },
            {
              label: '阳谷县',
              value: 3
            },
            {
              label: '茌平县',
              value: 4
            },
            {
              label: '莘县',
              value: 5
            },
            {
              label: '东阿县',
              value: 6
            },
            {
              label: '冠县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '滨州市',
          value: 15,
          children: [
            {
              label: '滨城区',
              value: 0
            },
            {
              label: '邹平县',
              value: 1
            },
            {
              label: '沾化县',
              value: 2
            },
            {
              label: '惠民县',
              value: 3
            },
            {
              label: '博兴县',
              value: 4
            },
            {
              label: '阳信县',
              value: 5
            },
            {
              label: '无棣县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '菏泽市',
          value: 16,
          children: [
            {
              label: '牡丹区',
              value: 0
            },
            {
              label: '鄄城县',
              value: 1
            },
            {
              label: '单县',
              value: 2
            },
            {
              label: '郓城县',
              value: 3
            },
            {
              label: '曹县',
              value: 4
            },
            {
              label: '定陶县',
              value: 5
            },
            {
              label: '巨野县',
              value: 6
            },
            {
              label: '东明县',
              value: 7
            },
            {
              label: '成武县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '其他',
          value: 17,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '河南省',
      value: 15,
      children: [
        {
          label: '郑州市',
          value: 0,
          children: [
            {
              label: '中原区',
              value: 0
            },
            {
              label: '金水区',
              value: 1
            },
            {
              label: '二七区',
              value: 2
            },
            {
              label: '管城回族区',
              value: 3
            },
            {
              label: '上街区',
              value: 4
            },
            {
              label: '惠济区',
              value: 5
            },
            {
              label: '巩义市',
              value: 6
            },
            {
              label: '新郑市',
              value: 7
            },
            {
              label: '新密市',
              value: 8
            },
            {
              label: '登封市',
              value: 9
            },
            {
              label: '荥阳市',
              value: 10
            },
            {
              label: '中牟县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '开封市',
          value: 1,
          children: [
            {
              label: '鼓楼区',
              value: 0
            },
            {
              label: '龙亭区',
              value: 1
            },
            {
              label: '顺河回族区',
              value: 2
            },
            {
              label: '禹王台区',
              value: 3
            },
            {
              label: '金明区',
              value: 4
            },
            {
              label: '开封县',
              value: 5
            },
            {
              label: '尉氏县',
              value: 6
            },
            {
              label: '兰考县',
              value: 7
            },
            {
              label: '杞县',
              value: 8
            },
            {
              label: '通许县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '洛阳市',
          value: 2,
          children: [
            {
              label: '西工区',
              value: 0
            },
            {
              label: '老城区',
              value: 1
            },
            {
              label: '涧西区',
              value: 2
            },
            {
              label: '瀍河回族区',
              value: 3
            },
            {
              label: '洛龙区',
              value: 4
            },
            {
              label: '吉利区',
              value: 5
            },
            {
              label: '偃师市',
              value: 6
            },
            {
              label: '孟津县',
              value: 7
            },
            {
              label: '汝阳县',
              value: 8
            },
            {
              label: '伊川县',
              value: 9
            },
            {
              label: '洛宁县',
              value: 10
            },
            {
              label: '嵩县',
              value: 11
            },
            {
              label: '宜阳县',
              value: 12
            },
            {
              label: '新安县',
              value: 13
            },
            {
              label: '栾川县',
              value: 14
            },
            {
              label: '其他',
              value: 15
            }
          ]
        },
        {
          label: '平顶山市',
          value: 3,
          children: [
            {
              label: '新华区',
              value: 0
            },
            {
              label: '卫东区',
              value: 1
            },
            {
              label: '湛河区',
              value: 2
            },
            {
              label: '石龙区',
              value: 3
            },
            {
              label: '汝州市',
              value: 4
            },
            {
              label: '舞钢市',
              value: 5
            },
            {
              label: '宝丰县',
              value: 6
            },
            {
              label: '叶县',
              value: 7
            },
            {
              label: '郏县',
              value: 8
            },
            {
              label: '鲁山县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '安阳市',
          value: 4,
          children: [
            {
              label: '北关区',
              value: 0
            },
            {
              label: '文峰区',
              value: 1
            },
            {
              label: '殷都区',
              value: 2
            },
            {
              label: '龙安区',
              value: 3
            },
            {
              label: '林州市',
              value: 4
            },
            {
              label: '安阳县',
              value: 5
            },
            {
              label: '滑县',
              value: 6
            },
            {
              label: '内黄县',
              value: 7
            },
            {
              label: '汤阴县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '鹤壁市',
          value: 5,
          children: [
            {
              label: '淇滨区',
              value: 0
            },
            {
              label: '山城区',
              value: 1
            },
            {
              label: '鹤山区',
              value: 2
            },
            {
              label: '浚县',
              value: 3
            },
            {
              label: '淇县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '新乡市',
          value: 6,
          children: [
            {
              label: '卫滨区',
              value: 0
            },
            {
              label: '红旗区',
              value: 1
            },
            {
              label: '凤泉区',
              value: 2
            },
            {
              label: '牧野区',
              value: 3
            },
            {
              label: '卫辉市',
              value: 4
            },
            {
              label: '辉县市',
              value: 5
            },
            {
              label: '新乡县',
              value: 6
            },
            {
              label: '获嘉县',
              value: 7
            },
            {
              label: '原阳县',
              value: 8
            },
            {
              label: '长垣县',
              value: 9
            },
            {
              label: '封丘县',
              value: 10
            },
            {
              label: '延津县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '焦作市',
          value: 7,
          children: [
            {
              label: '解放区',
              value: 0
            },
            {
              label: '中站区',
              value: 1
            },
            {
              label: '马村区',
              value: 2
            },
            {
              label: '山阳区',
              value: 3
            },
            {
              label: '沁阳市',
              value: 4
            },
            {
              label: '孟州市',
              value: 5
            },
            {
              label: '修武县',
              value: 6
            },
            {
              label: '温县',
              value: 7
            },
            {
              label: '武陟县',
              value: 8
            },
            {
              label: '博爱县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '濮阳市',
          value: 8,
          children: [
            {
              label: '华龙区',
              value: 0
            },
            {
              label: '濮阳县',
              value: 1
            },
            {
              label: '南乐县',
              value: 2
            },
            {
              label: '台前县',
              value: 3
            },
            {
              label: '清丰县',
              value: 4
            },
            {
              label: '范县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '许昌市',
          value: 9,
          children: [
            {
              label: '魏都区',
              value: 0
            },
            {
              label: '禹州市',
              value: 1
            },
            {
              label: '长葛市',
              value: 2
            },
            {
              label: '许昌县',
              value: 3
            },
            {
              label: '鄢陵县',
              value: 4
            },
            {
              label: '襄城县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '漯河市',
          value: 10,
          children: [
            {
              label: '源汇区',
              value: 0
            },
            {
              label: '郾城区',
              value: 1
            },
            {
              label: '召陵区',
              value: 2
            },
            {
              label: '临颍县',
              value: 3
            },
            {
              label: '舞阳县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '三门峡市',
          value: 11,
          children: [
            {
              label: '湖滨区',
              value: 0
            },
            {
              label: '义马市',
              value: 1
            },
            {
              label: '灵宝市',
              value: 2
            },
            {
              label: '渑池县',
              value: 3
            },
            {
              label: '卢氏县',
              value: 4
            },
            {
              label: '陕县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '南阳市',
          value: 12,
          children: [
            {
              label: '卧龙区',
              value: 0
            },
            {
              label: '宛城区',
              value: 1
            },
            {
              label: '邓州市',
              value: 2
            },
            {
              label: '桐柏县',
              value: 3
            },
            {
              label: '方城县',
              value: 4
            },
            {
              label: '淅川县',
              value: 5
            },
            {
              label: '镇平县',
              value: 6
            },
            {
              label: '唐河县',
              value: 7
            },
            {
              label: '南召县',
              value: 8
            },
            {
              label: '内乡县',
              value: 9
            },
            {
              label: '新野县',
              value: 10
            },
            {
              label: '社旗县',
              value: 11
            },
            {
              label: '西峡县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '商丘市',
          value: 13,
          children: [
            {
              label: '梁园区',
              value: 0
            },
            {
              label: '睢阳区',
              value: 1
            },
            {
              label: '永城市',
              value: 2
            },
            {
              label: '宁陵县',
              value: 3
            },
            {
              label: '虞城县',
              value: 4
            },
            {
              label: '民权县',
              value: 5
            },
            {
              label: '夏邑县',
              value: 6
            },
            {
              label: '柘城县',
              value: 7
            },
            {
              label: '睢县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '信阳市',
          value: 14,
          children: [
            {
              label: '浉河区',
              value: 0
            },
            {
              label: '平桥区',
              value: 1
            },
            {
              label: '潢川县',
              value: 2
            },
            {
              label: '淮滨县',
              value: 3
            },
            {
              label: '息县',
              value: 4
            },
            {
              label: '新县',
              value: 5
            },
            {
              label: '商城县',
              value: 6
            },
            {
              label: '固始县',
              value: 7
            },
            {
              label: '罗山县',
              value: 8
            },
            {
              label: '光山县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '周口市',
          value: 15,
          children: [
            {
              label: '川汇区',
              value: 0
            },
            {
              label: '项城市',
              value: 1
            },
            {
              label: '商水县',
              value: 2
            },
            {
              label: '淮阳县',
              value: 3
            },
            {
              label: '太康县',
              value: 4
            },
            {
              label: '鹿邑县',
              value: 5
            },
            {
              label: '西华县',
              value: 6
            },
            {
              label: '扶沟县',
              value: 7
            },
            {
              label: '沈丘县',
              value: 8
            },
            {
              label: '郸城县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '驻马店市',
          value: 16,
          children: [
            {
              label: '驿城区',
              value: 0
            },
            {
              label: '确山县',
              value: 1
            },
            {
              label: '新蔡县',
              value: 2
            },
            {
              label: '上蔡县',
              value: 3
            },
            {
              label: '西平县',
              value: 4
            },
            {
              label: '泌阳县',
              value: 5
            },
            {
              label: '平舆县',
              value: 6
            },
            {
              label: '汝南县',
              value: 7
            },
            {
              label: '遂平县',
              value: 8
            },
            {
              label: '正阳县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '焦作市',
          value: 17,
          children: [
            {
              label: '济源市',
              value: 0
            },
            {
              label: '其他',
              value: 1
            }
          ]
        },
        {
          label: '其他',
          value: 18,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '湖北省',
      value: 16,
      children: [
        {
          label: '武汉市',
          value: 0,
          children: [
            {
              label: '江岸区',
              value: 0
            },
            {
              label: '武昌区',
              value: 1
            },
            {
              label: '江汉区',
              value: 2
            },
            {
              label: '硚口区',
              value: 3
            },
            {
              label: '汉阳区',
              value: 4
            },
            {
              label: '青山区',
              value: 5
            },
            {
              label: '洪山区',
              value: 6
            },
            {
              label: '东西湖区',
              value: 7
            },
            {
              label: '汉南区',
              value: 8
            },
            {
              label: '蔡甸区',
              value: 9
            },
            {
              label: '江夏区',
              value: 10
            },
            {
              label: '黄陂区',
              value: 11
            },
            {
              label: '新洲区',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '黄石市',
          value: 1,
          children: [
            {
              label: '黄石港区',
              value: 0
            },
            {
              label: '西塞山区',
              value: 1
            },
            {
              label: '下陆区',
              value: 2
            },
            {
              label: '铁山区',
              value: 3
            },
            {
              label: '大冶市',
              value: 4
            },
            {
              label: '阳新县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '十堰市',
          value: 2,
          children: [
            {
              label: '张湾区',
              value: 0
            },
            {
              label: '茅箭区',
              value: 1
            },
            {
              label: '丹江口市',
              value: 2
            },
            {
              label: '郧县',
              value: 3
            },
            {
              label: '竹山县',
              value: 4
            },
            {
              label: '房县',
              value: 5
            },
            {
              label: '郧西县',
              value: 6
            },
            {
              label: '竹溪县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '荆州市',
          value: 3,
          children: [
            {
              label: '沙市区',
              value: 0
            },
            {
              label: '荆州区',
              value: 1
            },
            {
              label: '洪湖市',
              value: 2
            },
            {
              label: '石首市',
              value: 3
            },
            {
              label: '松滋市',
              value: 4
            },
            {
              label: '监利县',
              value: 5
            },
            {
              label: '公安县',
              value: 6
            },
            {
              label: '江陵县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '宜昌市',
          value: 4,
          children: [
            {
              label: '西陵区',
              value: 0
            },
            {
              label: '伍家岗区',
              value: 1
            },
            {
              label: '点军区',
              value: 2
            },
            {
              label: '猇亭区',
              value: 3
            },
            {
              label: '夷陵区',
              value: 4
            },
            {
              label: '宜都市',
              value: 5
            },
            {
              label: '当阳市',
              value: 6
            },
            {
              label: '枝江市',
              value: 7
            },
            {
              label: '秭归县',
              value: 8
            },
            {
              label: '远安县',
              value: 9
            },
            {
              label: '兴山县',
              value: 10
            },
            {
              label: '五峰土家族自治县',
              value: 11
            },
            {
              label: '长阳土家族自治县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '襄樊市',
          value: 5,
          children: [
            {
              label: '襄城区',
              value: 0
            },
            {
              label: '樊城区',
              value: 1
            },
            {
              label: '襄阳区',
              value: 2
            },
            {
              label: '老河口市',
              value: 3
            },
            {
              label: '枣阳市',
              value: 4
            },
            {
              label: '宜城市',
              value: 5
            },
            {
              label: '南漳县',
              value: 6
            },
            {
              label: '谷城县',
              value: 7
            },
            {
              label: '保康县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '鄂州市',
          value: 6,
          children: [
            {
              label: '鄂城区',
              value: 0
            },
            {
              label: '华容区',
              value: 1
            },
            {
              label: '梁子湖区',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '荆门市',
          value: 7,
          children: [
            {
              label: '东宝区',
              value: 0
            },
            {
              label: '掇刀区',
              value: 1
            },
            {
              label: '钟祥市',
              value: 2
            },
            {
              label: '京山县',
              value: 3
            },
            {
              label: '沙洋县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '孝感市',
          value: 8,
          children: [
            {
              label: '孝南区',
              value: 0
            },
            {
              label: '应城市',
              value: 1
            },
            {
              label: '安陆市',
              value: 2
            },
            {
              label: '汉川市',
              value: 3
            },
            {
              label: '云梦县',
              value: 4
            },
            {
              label: '大悟县',
              value: 5
            },
            {
              label: '孝昌县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '黄冈市',
          value: 9,
          children: [
            {
              label: '黄州区',
              value: 0
            },
            {
              label: '麻城市',
              value: 1
            },
            {
              label: '武穴市',
              value: 2
            },
            {
              label: '红安县',
              value: 3
            },
            {
              label: '罗田县',
              value: 4
            },
            {
              label: '浠水县',
              value: 5
            },
            {
              label: '蕲春县',
              value: 6
            },
            {
              label: '黄梅县',
              value: 7
            },
            {
              label: '英山县',
              value: 8
            },
            {
              label: '团风县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '咸宁市',
          value: 10,
          children: [
            {
              label: '咸安区',
              value: 0
            },
            {
              label: '赤壁市',
              value: 1
            },
            {
              label: '嘉鱼县',
              value: 2
            },
            {
              label: '通山县',
              value: 3
            },
            {
              label: '崇阳县',
              value: 4
            },
            {
              label: '通城县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '随州市',
          value: 11,
          children: [
            {
              label: '曾都区',
              value: 0
            },
            {
              label: '广水市',
              value: 1
            },
            {
              label: '其他',
              value: 2
            }
          ]
        },
        {
          label: '恩施土家族苗族自治州',
          value: 12,
          children: [
            {
              label: '恩施市',
              value: 0
            },
            {
              label: '利川市',
              value: 1
            },
            {
              label: '建始县',
              value: 2
            },
            {
              label: '来凤县',
              value: 3
            },
            {
              label: '巴东县',
              value: 4
            },
            {
              label: '鹤峰县',
              value: 5
            },
            {
              label: '宣恩县',
              value: 6
            },
            {
              label: '咸丰县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '仙桃市',
          value: 13,
          children: [
            {
              label: '仙桃',
              value: 0
            }
          ]
        },
        {
          label: '天门市',
          value: 14,
          children: [
            {
              label: '天门',
              value: 0
            }
          ]
        },
        {
          label: '潜江市',
          value: 15,
          children: [
            {
              label: '潜江',
              value: 0
            }
          ]
        },
        {
          label: '神农架林区',
          value: 16,
          children: [
            {
              label: '神农架林区',
              value: 0
            }
          ]
        },
        {
          label: '其他',
          value: 17,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '湖南省',
      value: 17,
      children: [
        {
          label: '长沙市',
          value: 0,
          children: [
            {
              label: '岳麓区',
              value: 0
            },
            {
              label: '芙蓉区',
              value: 1
            },
            {
              label: '天心区',
              value: 2
            },
            {
              label: '开福区',
              value: 3
            },
            {
              label: '雨花区',
              value: 4
            },
            {
              label: '浏阳市',
              value: 5
            },
            {
              label: '长沙县',
              value: 6
            },
            {
              label: '望城县',
              value: 7
            },
            {
              label: '宁乡县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '株洲市',
          value: 1,
          children: [
            {
              label: '天元区',
              value: 0
            },
            {
              label: '荷塘区',
              value: 1
            },
            {
              label: '芦淞区',
              value: 2
            },
            {
              label: '石峰区',
              value: 3
            },
            {
              label: '醴陵市',
              value: 4
            },
            {
              label: '株洲县',
              value: 5
            },
            {
              label: '炎陵县',
              value: 6
            },
            {
              label: '茶陵县',
              value: 7
            },
            {
              label: '攸县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '湘潭市',
          value: 2,
          children: [
            {
              label: '岳塘区',
              value: 0
            },
            {
              label: '雨湖区',
              value: 1
            },
            {
              label: '湘乡市',
              value: 2
            },
            {
              label: '韶山市',
              value: 3
            },
            {
              label: '湘潭县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '衡阳市',
          value: 3,
          children: [
            {
              label: '雁峰区',
              value: 0
            },
            {
              label: '珠晖区',
              value: 1
            },
            {
              label: '石鼓区',
              value: 2
            },
            {
              label: '蒸湘区',
              value: 3
            },
            {
              label: '南岳区',
              value: 4
            },
            {
              label: '耒阳市',
              value: 5
            },
            {
              label: '常宁市',
              value: 6
            },
            {
              label: '衡阳县',
              value: 7
            },
            {
              label: '衡东县',
              value: 8
            },
            {
              label: '衡山县',
              value: 9
            },
            {
              label: '衡南县',
              value: 10
            },
            {
              label: '祁东县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '邵阳市',
          value: 4,
          children: [
            {
              label: '双清区',
              value: 0
            },
            {
              label: '大祥区',
              value: 1
            },
            {
              label: '北塔区',
              value: 2
            },
            {
              label: '武冈市',
              value: 3
            },
            {
              label: '邵东县',
              value: 4
            },
            {
              label: '洞口县',
              value: 5
            },
            {
              label: '新邵县',
              value: 6
            },
            {
              label: '绥宁县',
              value: 7
            },
            {
              label: '新宁县',
              value: 8
            },
            {
              label: '邵阳县',
              value: 9
            },
            {
              label: '隆回县',
              value: 10
            },
            {
              label: '城步苗族自治县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '岳阳市',
          value: 5,
          children: [
            {
              label: '岳阳楼区',
              value: 0
            },
            {
              label: '云溪区',
              value: 1
            },
            {
              label: '君山区',
              value: 2
            },
            {
              label: '临湘市',
              value: 3
            },
            {
              label: '汨罗市',
              value: 4
            },
            {
              label: '岳阳县',
              value: 5
            },
            {
              label: '湘阴县',
              value: 6
            },
            {
              label: '平江县',
              value: 7
            },
            {
              label: '华容县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '常德市',
          value: 6,
          children: [
            {
              label: '武陵区',
              value: 0
            },
            {
              label: '鼎城区',
              value: 1
            },
            {
              label: '津市市',
              value: 2
            },
            {
              label: '澧县',
              value: 3
            },
            {
              label: '临澧县',
              value: 4
            },
            {
              label: '桃源县',
              value: 5
            },
            {
              label: '汉寿县',
              value: 6
            },
            {
              label: '安乡县',
              value: 7
            },
            {
              label: '石门县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '张家界市',
          value: 7,
          children: [
            {
              label: '永定区',
              value: 0
            },
            {
              label: '武陵源区',
              value: 1
            },
            {
              label: '慈利县',
              value: 2
            },
            {
              label: '桑植县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '益阳市',
          value: 8,
          children: [
            {
              label: '赫山区',
              value: 0
            },
            {
              label: '资阳区',
              value: 1
            },
            {
              label: '沅江市',
              value: 2
            },
            {
              label: '桃江县',
              value: 3
            },
            {
              label: '南县',
              value: 4
            },
            {
              label: '安化县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '郴州市',
          value: 9,
          children: [
            {
              label: '北湖区',
              value: 0
            },
            {
              label: '苏仙区',
              value: 1
            },
            {
              label: '资兴市',
              value: 2
            },
            {
              label: '宜章县',
              value: 3
            },
            {
              label: '汝城县',
              value: 4
            },
            {
              label: '安仁县',
              value: 5
            },
            {
              label: '嘉禾县',
              value: 6
            },
            {
              label: '临武县',
              value: 7
            },
            {
              label: '桂东县',
              value: 8
            },
            {
              label: '永兴县',
              value: 9
            },
            {
              label: '桂阳县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '永州市',
          value: 10,
          children: [
            {
              label: '冷水滩区',
              value: 0
            },
            {
              label: '零陵区',
              value: 1
            },
            {
              label: '祁阳县',
              value: 2
            },
            {
              label: '蓝山县',
              value: 3
            },
            {
              label: '宁远县',
              value: 4
            },
            {
              label: '新田县',
              value: 5
            },
            {
              label: '东安县',
              value: 6
            },
            {
              label: '江永县',
              value: 7
            },
            {
              label: '道县',
              value: 8
            },
            {
              label: '双牌县',
              value: 9
            },
            {
              label: '江华瑶族自治县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '怀化市',
          value: 11,
          children: [
            {
              label: '鹤城区',
              value: 0
            },
            {
              label: '洪江市',
              value: 1
            },
            {
              label: '会同县',
              value: 2
            },
            {
              label: '沅陵县',
              value: 3
            },
            {
              label: '辰溪县',
              value: 4
            },
            {
              label: '溆浦县',
              value: 5
            },
            {
              label: '中方县',
              value: 6
            },
            {
              label: '新晃侗族自治县',
              value: 7
            },
            {
              label: '芷江侗族自治县',
              value: 8
            },
            {
              label: '通道侗族自治县',
              value: 9
            },
            {
              label: '靖州苗族侗族自治县',
              value: 10
            },
            {
              label: '麻阳苗族自治县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '娄底市',
          value: 12,
          children: [
            {
              label: '娄星区',
              value: 0
            },
            {
              label: '冷水江市',
              value: 1
            },
            {
              label: '涟源市',
              value: 2
            },
            {
              label: '新化县',
              value: 3
            },
            {
              label: '双峰县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '湘西土家族苗族自治州',
          value: 13,
          children: [
            {
              label: '吉首市',
              value: 0
            },
            {
              label: '古丈县',
              value: 1
            },
            {
              label: '龙山县',
              value: 2
            },
            {
              label: '永顺县',
              value: 3
            },
            {
              label: '凤凰县',
              value: 4
            },
            {
              label: '泸溪县',
              value: 5
            },
            {
              label: '保靖县',
              value: 6
            },
            {
              label: '花垣县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '其他',
          value: 14,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '广东省',
      value: 18,
      children: [
        {
          label: '广州市',
          value: 0,
          children: [
            {
              label: '越秀区',
              value: 0
            },
            {
              label: '荔湾区',
              value: 1
            },
            {
              label: '海珠区',
              value: 2
            },
            {
              label: '天河区',
              value: 3
            },
            {
              label: '白云区',
              value: 4
            },
            {
              label: '黄埔区',
              value: 5
            },
            {
              label: '番禺区',
              value: 6
            },
            {
              label: '花都区',
              value: 7
            },
            {
              label: '南沙区',
              value: 8
            },
            {
              label: '萝岗区',
              value: 9
            },
            {
              label: '增城市',
              value: 10
            },
            {
              label: '从化市',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '深圳市',
          value: 1,
          children: [
            {
              label: '福田区',
              value: 0
            },
            {
              label: '罗湖区',
              value: 1
            },
            {
              label: '南山区',
              value: 2
            },
            {
              label: '宝安区',
              value: 3
            },
            {
              label: '龙岗区',
              value: 4
            },
            {
              label: '盐田区',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '东莞市',
          value: 2,
          children: [
            {
              label: '莞城',
              value: 0
            },
            {
              label: '常平',
              value: 1
            },
            {
              label: '塘厦',
              value: 2
            },
            {
              label: '塘厦',
              value: 3
            },
            {
              label: '塘厦',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '中山市',
          value: 3,
          children: [
            {
              label: '中山',
              value: 0
            }
          ]
        },
        {
          label: '潮州市',
          value: 4,
          children: [
            {
              label: '湘桥区',
              value: 0
            },
            {
              label: '潮安县',
              value: 1
            },
            {
              label: '饶平县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '揭阳市',
          value: 5,
          children: [
            {
              label: '榕城区',
              value: 0
            },
            {
              label: '揭东县',
              value: 1
            },
            {
              label: '揭西县',
              value: 2
            },
            {
              label: '惠来县',
              value: 3
            },
            {
              label: '普宁市',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '云浮市',
          value: 6,
          children: [
            {
              label: '云城区',
              value: 0
            },
            {
              label: '新兴县',
              value: 1
            },
            {
              label: '郁南县',
              value: 2
            },
            {
              label: '云安县',
              value: 3
            },
            {
              label: '罗定市',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '珠海市',
          value: 7,
          children: [
            {
              label: '香洲区',
              value: 0
            },
            {
              label: '斗门区',
              value: 1
            },
            {
              label: '金湾区',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '汕头市',
          value: 8,
          children: [
            {
              label: '金平区',
              value: 0
            },
            {
              label: '濠江区',
              value: 1
            },
            {
              label: '龙湖区',
              value: 2
            },
            {
              label: '潮阳区',
              value: 3
            },
            {
              label: '潮南区',
              value: 4
            },
            {
              label: '澄海区',
              value: 5
            },
            {
              label: '南澳县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '韶关市',
          value: 9,
          children: [
            {
              label: '浈江区',
              value: 0
            },
            {
              label: '武江区',
              value: 1
            },
            {
              label: '曲江区',
              value: 2
            },
            {
              label: '乐昌市',
              value: 3
            },
            {
              label: '南雄市',
              value: 4
            },
            {
              label: '始兴县',
              value: 5
            },
            {
              label: '仁化县',
              value: 6
            },
            {
              label: '翁源县',
              value: 7
            },
            {
              label: '新丰县',
              value: 8
            },
            {
              label: '乳源瑶族自治县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '佛山市',
          value: 10,
          children: [
            {
              label: '禅城区',
              value: 0
            },
            {
              label: '南海区',
              value: 1
            },
            {
              label: '顺德区',
              value: 2
            },
            {
              label: '三水区',
              value: 3
            },
            {
              label: '高明区',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '江门市',
          value: 11,
          children: [
            {
              label: '蓬江区',
              value: 0
            },
            {
              label: '江海区',
              value: 1
            },
            {
              label: '新会区',
              value: 2
            },
            {
              label: '恩平市',
              value: 3
            },
            {
              label: '台山市',
              value: 4
            },
            {
              label: '开平市',
              value: 5
            },
            {
              label: '鹤山市',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '湛江市',
          value: 12,
          children: [
            {
              label: '赤坎区',
              value: 0
            },
            {
              label: '霞山区',
              value: 1
            },
            {
              label: '坡头区',
              value: 2
            },
            {
              label: '麻章区',
              value: 3
            },
            {
              label: '吴川市',
              value: 4
            },
            {
              label: '廉江市',
              value: 5
            },
            {
              label: '雷州市',
              value: 6
            },
            {
              label: '遂溪县',
              value: 7
            },
            {
              label: '徐闻县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '茂名市',
          value: 13,
          children: [
            {
              label: '茂南区',
              value: 0
            },
            {
              label: '茂港区',
              value: 1
            },
            {
              label: '化州市',
              value: 2
            },
            {
              label: '信宜市',
              value: 3
            },
            {
              label: '高州市',
              value: 4
            },
            {
              label: '电白县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '肇庆市',
          value: 14,
          children: [
            {
              label: '端州区',
              value: 0
            },
            {
              label: '鼎湖区',
              value: 1
            },
            {
              label: '高要市',
              value: 2
            },
            {
              label: '四会市',
              value: 3
            },
            {
              label: '广宁县',
              value: 4
            },
            {
              label: '怀集县',
              value: 5
            },
            {
              label: '封开县',
              value: 6
            },
            {
              label: '德庆县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '惠州市',
          value: 15,
          children: [
            {
              label: '惠城区',
              value: 0
            },
            {
              label: '惠阳区',
              value: 1
            },
            {
              label: '博罗县',
              value: 2
            },
            {
              label: '惠东县',
              value: 3
            },
            {
              label: '龙门县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '梅州市',
          value: 16,
          children: [
            {
              label: '梅江区',
              value: 0
            },
            {
              label: '兴宁市',
              value: 1
            },
            {
              label: '梅县',
              value: 2
            },
            {
              label: '大埔县',
              value: 3
            },
            {
              label: '丰顺县',
              value: 4
            },
            {
              label: '五华县',
              value: 5
            },
            {
              label: '平远县',
              value: 6
            },
            {
              label: '蕉岭县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '汕尾市',
          value: 17,
          children: [
            {
              label: '城区',
              value: 0
            },
            {
              label: '陆丰市',
              value: 1
            },
            {
              label: '海丰县',
              value: 2
            },
            {
              label: '陆河县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '河源市',
          value: 18,
          children: [
            {
              label: '源城区',
              value: 0
            },
            {
              label: '紫金县',
              value: 1
            },
            {
              label: '龙川县',
              value: 2
            },
            {
              label: '连平县',
              value: 3
            },
            {
              label: '和平县',
              value: 4
            },
            {
              label: '东源县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '阳江市',
          value: 19,
          children: [
            {
              label: '江城区',
              value: 0
            },
            {
              label: '阳春市',
              value: 1
            },
            {
              label: '阳西县',
              value: 2
            },
            {
              label: '阳东县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '清远市',
          value: 20,
          children: [
            {
              label: '清城区',
              value: 0
            },
            {
              label: '英德市',
              value: 1
            },
            {
              label: '连州市',
              value: 2
            },
            {
              label: '佛冈县',
              value: 3
            },
            {
              label: '阳山县',
              value: 4
            },
            {
              label: '清新县',
              value: 5
            },
            {
              label: '连山壮族瑶族自治县',
              value: 6
            },
            {
              label: '连南瑶族自治县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        }
      ]
    },
    {
      label: '广西',
      value: 19,
      children: [
        {
          label: '南宁市',
          value: 0,
          children: [
            {
              label: '青秀区',
              value: 0
            },
            {
              label: '兴宁区',
              value: 1
            },
            {
              label: '西乡塘区',
              value: 2
            },
            {
              label: '良庆区',
              value: 3
            },
            {
              label: '江南区',
              value: 4
            },
            {
              label: '邕宁区',
              value: 5
            },
            {
              label: '武鸣县',
              value: 6
            },
            {
              label: '隆安县',
              value: 7
            },
            {
              label: '马山县',
              value: 8
            },
            {
              label: '上林县',
              value: 9
            },
            {
              label: '宾阳县',
              value: 10
            },
            {
              label: '横县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '柳州市',
          value: 1,
          children: [
            {
              label: '城中区',
              value: 0
            },
            {
              label: '鱼峰区',
              value: 1
            },
            {
              label: '柳北区',
              value: 2
            },
            {
              label: '柳南区',
              value: 3
            },
            {
              label: '柳江县',
              value: 4
            },
            {
              label: '柳城县',
              value: 5
            },
            {
              label: '鹿寨县',
              value: 6
            },
            {
              label: '融安县',
              value: 7
            },
            {
              label: '融水苗族自治县',
              value: 8
            },
            {
              label: '三江侗族自治县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '桂林市',
          value: 2,
          children: [
            {
              label: '象山区',
              value: 0
            },
            {
              label: '秀峰区',
              value: 1
            },
            {
              label: '叠彩区',
              value: 2
            },
            {
              label: '七星区',
              value: 3
            },
            {
              label: '雁山区',
              value: 4
            },
            {
              label: '阳朔县',
              value: 5
            },
            {
              label: '临桂县',
              value: 6
            },
            {
              label: '灵川县',
              value: 7
            },
            {
              label: '全州县',
              value: 8
            },
            {
              label: '平乐县',
              value: 9
            },
            {
              label: '兴安县',
              value: 10
            },
            {
              label: '灌阳县',
              value: 11
            },
            {
              label: '荔浦县',
              value: 12
            },
            {
              label: '资源县',
              value: 13
            },
            {
              label: '永福县',
              value: 14
            },
            {
              label: '龙胜各族自治县',
              value: 15
            },
            {
              label: '恭城瑶族自治县',
              value: 16
            },
            {
              label: '其他',
              value: 17
            }
          ]
        },
        {
          label: '梧州市',
          value: 3,
          children: [
            {
              label: '万秀区',
              value: 0
            },
            {
              label: '蝶山区',
              value: 1
            },
            {
              label: '长洲区',
              value: 2
            },
            {
              label: '岑溪市',
              value: 3
            },
            {
              label: '苍梧县',
              value: 4
            },
            {
              label: '藤县',
              value: 5
            },
            {
              label: '蒙山县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '北海市',
          value: 4,
          children: [
            {
              label: '海城区',
              value: 0
            },
            {
              label: '银海区',
              value: 1
            },
            {
              label: '铁山港区',
              value: 2
            },
            {
              label: '合浦县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '防城港市',
          value: 5,
          children: [
            {
              label: '港口区',
              value: 0
            },
            {
              label: '防城区',
              value: 1
            },
            {
              label: '东兴市',
              value: 2
            },
            {
              label: '上思县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '钦州市',
          value: 6,
          children: [
            {
              label: '钦南区',
              value: 0
            },
            {
              label: '钦北区',
              value: 1
            },
            {
              label: '灵山县',
              value: 2
            },
            {
              label: '浦北县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '贵港市',
          value: 7,
          children: [
            {
              label: '港北区',
              value: 0
            },
            {
              label: '港南区',
              value: 1
            },
            {
              label: '覃塘区',
              value: 2
            },
            {
              label: '桂平市',
              value: 3
            },
            {
              label: '平南县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '玉林市',
          value: 8,
          children: [
            {
              label: '玉州区',
              value: 0
            },
            {
              label: '北流市',
              value: 1
            },
            {
              label: '容县',
              value: 2
            },
            {
              label: '陆川县',
              value: 3
            },
            {
              label: '博白县',
              value: 4
            },
            {
              label: '兴业县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '百色市',
          value: 9,
          children: [
            {
              label: '右江区',
              value: 0
            },
            {
              label: '凌云县',
              value: 1
            },
            {
              label: '平果县',
              value: 2
            },
            {
              label: '西林县',
              value: 3
            },
            {
              label: '乐业县',
              value: 4
            },
            {
              label: '德保县',
              value: 5
            },
            {
              label: '田林县',
              value: 6
            },
            {
              label: '田阳县',
              value: 7
            },
            {
              label: '靖西县',
              value: 8
            },
            {
              label: '田东县',
              value: 9
            },
            {
              label: '那坡县',
              value: 10
            },
            {
              label: '隆林各族自治县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '贺州市',
          value: 10,
          children: [
            {
              label: '八步区',
              value: 0
            },
            {
              label: '钟山县',
              value: 1
            },
            {
              label: '昭平县',
              value: 2
            },
            {
              label: '富川瑶族自治县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '河池市',
          value: 11,
          children: [
            {
              label: '金城江区',
              value: 0
            },
            {
              label: '宜州市',
              value: 1
            },
            {
              label: '天峨县',
              value: 2
            },
            {
              label: '凤山县',
              value: 3
            },
            {
              label: '南丹县',
              value: 4
            },
            {
              label: '东兰县',
              value: 5
            },
            {
              label: '都安瑶族自治县',
              value: 6
            },
            {
              label: '罗城仫佬族自治县',
              value: 7
            },
            {
              label: '巴马瑶族自治县',
              value: 8
            },
            {
              label: '环江毛南族自治县',
              value: 9
            },
            {
              label: '大化瑶族自治县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '来宾市',
          value: 12,
          children: [
            {
              label: '兴宾区',
              value: 0
            },
            {
              label: '合山市',
              value: 1
            },
            {
              label: '象州县',
              value: 2
            },
            {
              label: '武宣县',
              value: 3
            },
            {
              label: '忻城县',
              value: 4
            },
            {
              label: '金秀瑶族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '崇左市',
          value: 13,
          children: [
            {
              label: '江州区',
              value: 0
            },
            {
              label: '凭祥市',
              value: 1
            },
            {
              label: '宁明县',
              value: 2
            },
            {
              label: '扶绥县',
              value: 3
            },
            {
              label: '龙州县',
              value: 4
            },
            {
              label: '大新县',
              value: 5
            },
            {
              label: '天等县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '其他市',
          value: 14,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '海南省',
      value: 20,
      children: [
        {
          label: '海口市',
          value: 0,
          children: [
            {
              label: '龙华区',
              value: 0
            },
            {
              label: '秀英区',
              value: 1
            },
            {
              label: '琼山区',
              value: 2
            },
            {
              label: '美兰区',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '三亚市',
          value: 1,
          children: [
            {
              label: '三亚市',
              value: 0
            },
            {
              label: '其他',
              value: 1
            }
          ]
        },
        {
          label: '五指山市',
          value: 2,
          children: [
            {
              label: '五指山',
              value: 0
            }
          ]
        },
        {
          label: '琼海市',
          value: 3,
          children: [
            {
              label: '琼海',
              value: 0
            }
          ]
        },
        {
          label: '儋州市',
          value: 4,
          children: [
            {
              label: '儋州',
              value: 0
            }
          ]
        },
        {
          label: '文昌市',
          value: 5,
          children: [
            {
              label: '文昌',
              value: 0
            }
          ]
        },
        {
          label: '万宁市',
          value: 6,
          children: [
            {
              label: '万宁',
              value: 0
            }
          ]
        },
        {
          label: '东方市',
          value: 7,
          children: [
            {
              label: '东方',
              value: 0
            }
          ]
        },
        {
          label: '澄迈县',
          value: 8,
          children: [
            {
              label: '澄迈县',
              value: 0
            }
          ]
        },
        {
          label: '定安县',
          value: 9,
          children: [
            {
              label: '定安县',
              value: 0
            }
          ]
        },
        {
          label: '屯昌县',
          value: 10,
          children: [
            {
              label: '屯昌县',
              value: 0
            }
          ]
        },
        {
          label: '临高县',
          value: 11,
          children: [
            {
              label: '临高县',
              value: 0
            }
          ]
        },
        {
          label: '白沙黎族自治县',
          value: 12,
          children: [
            {
              label: '白沙黎族自治县',
              value: 0
            }
          ]
        },
        {
          label: '昌江黎族自治县',
          value: 13,
          children: [
            {
              label: '昌江黎族自治县',
              value: 0
            }
          ]
        },
        {
          label: '乐东黎族自治县',
          value: 14,
          children: [
            {
              label: '乐东黎族自治县',
              value: 0
            }
          ]
        },
        {
          label: '陵水黎族自治县',
          value: 15,
          children: [
            {
              label: '陵水黎族自治县',
              value: 0
            }
          ]
        },
        {
          label: '保亭黎族苗族自治县',
          value: 16,
          children: [
            {
              label: '保亭黎族苗族自治县',
              value: 0
            }
          ]
        },
        {
          label: '琼中黎族苗族自治县',
          value: 17,
          children: [
            {
              label: '琼中黎族苗族自治县',
              value: 0
            }
          ]
        },
        {
          label: '其他',
          value: 18,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '重庆市',
      value: 21,
      children: [
        {
          label: '重庆市',
          value: 0,
          children: [
            {
              label: '渝中区',
              value: 0
            },
            {
              label: '大渡口区',
              value: 1
            },
            {
              label: '江北区',
              value: 2
            },
            {
              label: '南岸区',
              value: 3
            },
            {
              label: '北碚区',
              value: 4
            },
            {
              label: '渝北区',
              value: 5
            },
            {
              label: '巴南区',
              value: 6
            },
            {
              label: '长寿区',
              value: 7
            },
            {
              label: '双桥区',
              value: 8
            },
            {
              label: '沙坪坝区',
              value: 9
            },
            {
              label: '万盛区',
              value: 10
            },
            {
              label: '万州区',
              value: 11
            },
            {
              label: '涪陵区',
              value: 12
            },
            {
              label: '黔江区',
              value: 13
            },
            {
              label: '永川区',
              value: 14
            },
            {
              label: '合川区',
              value: 15
            },
            {
              label: '江津区',
              value: 16
            },
            {
              label: '九龙坡区',
              value: 17
            },
            {
              label: '南川区',
              value: 18
            },
            {
              label: '綦江县',
              value: 19
            },
            {
              label: '潼南县',
              value: 20
            },
            {
              label: '荣昌县',
              value: 21
            },
            {
              label: '璧山县',
              value: 22
            },
            {
              label: '大足县',
              value: 23
            },
            {
              label: '铜梁县',
              value: 24
            },
            {
              label: '梁平县',
              value: 25
            },
            {
              label: '开县',
              value: 26
            },
            {
              label: '忠县',
              value: 27
            },
            {
              label: '城口县',
              value: 28
            },
            {
              label: '垫江县',
              value: 29
            },
            {
              label: '武隆县',
              value: 30
            },
            {
              label: '丰都县',
              value: 31
            },
            {
              label: '奉节县',
              value: 32
            },
            {
              label: '云阳县',
              value: 33
            },
            {
              label: '巫溪县',
              value: 34
            },
            {
              label: '巫山县',
              value: 35
            },
            {
              label: '石柱土家族自治县',
              value: 36
            },
            {
              label: '秀山土家族苗族自治县',
              value: 37
            },
            {
              label: '酉阳土家族苗族自治县',
              value: 38
            },
            {
              label: '彭水苗族土家族自治县',
              value: 39
            },
            {
              label: '其他',
              value: 40
            }
          ]
        }
      ]
    },
    {
      label: '四川省',
      value: 22,
      children: [
        {
          label: '成都市',
          value: 0,
          children: [
            {
              label: '青羊区',
              value: 0
            },
            {
              label: '锦江区',
              value: 1
            },
            {
              label: '金牛区',
              value: 2
            },
            {
              label: '武侯区',
              value: 3
            },
            {
              label: '成华区',
              value: 4
            },
            {
              label: '龙泉驿区',
              value: 5
            },
            {
              label: '青白江区',
              value: 6
            },
            {
              label: '新都区',
              value: 7
            },
            {
              label: '温江区',
              value: 8
            },
            {
              label: '都江堰市',
              value: 9
            },
            {
              label: '彭州市',
              value: 10
            },
            {
              label: '邛崃市',
              value: 11
            },
            {
              label: '崇州市',
              value: 12
            },
            {
              label: '金堂县',
              value: 13
            },
            {
              label: '郫县',
              value: 14
            },
            {
              label: '新津县',
              value: 15
            },
            {
              label: '双流县',
              value: 16
            },
            {
              label: '蒲江县',
              value: 17
            },
            {
              label: '大邑县',
              value: 18
            },
            {
              label: '其他',
              value: 19
            }
          ]
        },
        {
          label: '自贡市',
          value: 1,
          children: [
            {
              label: '大安区',
              value: 0
            },
            {
              label: '自流井区',
              value: 1
            },
            {
              label: '贡井区',
              value: 2
            },
            {
              label: '沿滩区',
              value: 3
            },
            {
              label: '荣县',
              value: 4
            },
            {
              label: '富顺县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '攀枝花市',
          value: 2,
          children: [
            {
              label: '仁和区',
              value: 0
            },
            {
              label: '米易县',
              value: 1
            },
            {
              label: '盐边县',
              value: 2
            },
            {
              label: '东区',
              value: 3
            },
            {
              label: '西区',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '泸州市',
          value: 3,
          children: [
            {
              label: '江阳区',
              value: 0
            },
            {
              label: '纳溪区',
              value: 1
            },
            {
              label: '龙马潭区',
              value: 2
            },
            {
              label: '泸县',
              value: 3
            },
            {
              label: '合江县',
              value: 4
            },
            {
              label: '叙永县',
              value: 5
            },
            {
              label: '古蔺县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '德阳市',
          value: 4,
          children: [
            {
              label: '旌阳区',
              value: 0
            },
            {
              label: '广汉市',
              value: 1
            },
            {
              label: '什邡市',
              value: 2
            },
            {
              label: '绵竹市',
              value: 3
            },
            {
              label: '罗江县',
              value: 4
            },
            {
              label: '中江县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '绵阳市',
          value: 5,
          children: [
            {
              label: '涪城区',
              value: 0
            },
            {
              label: '游仙区',
              value: 1
            },
            {
              label: '江油市',
              value: 2
            },
            {
              label: '盐亭县',
              value: 3
            },
            {
              label: '三台县',
              value: 4
            },
            {
              label: '平武县',
              value: 5
            },
            {
              label: '安县',
              value: 6
            },
            {
              label: '梓潼县',
              value: 7
            },
            {
              label: '北川羌族自治县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '广元市',
          value: 6,
          children: [
            {
              label: '元坝区',
              value: 0
            },
            {
              label: '朝天区',
              value: 1
            },
            {
              label: '青川县',
              value: 2
            },
            {
              label: '旺苍县',
              value: 3
            },
            {
              label: '剑阁县',
              value: 4
            },
            {
              label: '苍溪县',
              value: 5
            },
            {
              label: '市中区',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '遂宁市',
          value: 7,
          children: [
            {
              label: '船山区',
              value: 0
            },
            {
              label: '安居区',
              value: 1
            },
            {
              label: '射洪县',
              value: 2
            },
            {
              label: '蓬溪县',
              value: 3
            },
            {
              label: '大英县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '内江市',
          value: 8,
          children: [
            {
              label: '市中区',
              value: 0
            },
            {
              label: '东兴区',
              value: 1
            },
            {
              label: '资中县',
              value: 2
            },
            {
              label: '隆昌县',
              value: 3
            },
            {
              label: '威远县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '乐山市',
          value: 9,
          children: [
            {
              label: '市中区',
              value: 0
            },
            {
              label: '五通桥区',
              value: 1
            },
            {
              label: '沙湾区',
              value: 2
            },
            {
              label: '金口河区',
              value: 3
            },
            {
              label: '峨眉山市',
              value: 4
            },
            {
              label: '夹江县',
              value: 5
            },
            {
              label: '井研县',
              value: 6
            },
            {
              label: '犍为县',
              value: 7
            },
            {
              label: '沐川县',
              value: 8
            },
            {
              label: '马边彝族自治县',
              value: 9
            },
            {
              label: '峨边彝族自治县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '南充',
          value: 10,
          children: [
            {
              label: '顺庆区',
              value: 0
            },
            {
              label: '高坪区',
              value: 1
            },
            {
              label: '嘉陵区',
              value: 2
            },
            {
              label: '阆中市',
              value: 3
            },
            {
              label: '营山县',
              value: 4
            },
            {
              label: '蓬安县',
              value: 5
            },
            {
              label: '仪陇县',
              value: 6
            },
            {
              label: '南部县',
              value: 7
            },
            {
              label: '西充县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '眉山市',
          value: 11,
          children: [
            {
              label: '东坡区',
              value: 0
            },
            {
              label: '仁寿县',
              value: 1
            },
            {
              label: '彭山县',
              value: 2
            },
            {
              label: '洪雅县',
              value: 3
            },
            {
              label: '丹棱县',
              value: 4
            },
            {
              label: '青神县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '宜宾市',
          value: 12,
          children: [
            {
              label: '翠屏区',
              value: 0
            },
            {
              label: '宜宾县',
              value: 1
            },
            {
              label: '兴文县',
              value: 2
            },
            {
              label: '南溪县',
              value: 3
            },
            {
              label: '珙县',
              value: 4
            },
            {
              label: '长宁县',
              value: 5
            },
            {
              label: '高县',
              value: 6
            },
            {
              label: '江安县',
              value: 7
            },
            {
              label: '筠连县',
              value: 8
            },
            {
              label: '屏山县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '广安市',
          value: 13,
          children: [
            {
              label: '广安区',
              value: 0
            },
            {
              label: '华蓥市',
              value: 1
            },
            {
              label: '岳池县',
              value: 2
            },
            {
              label: '邻水县',
              value: 3
            },
            {
              label: '武胜县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '达州市',
          value: 14,
          children: [
            {
              label: '通川区',
              value: 0
            },
            {
              label: '万源市',
              value: 1
            },
            {
              label: '达县',
              value: 2
            },
            {
              label: '渠县',
              value: 3
            },
            {
              label: '宣汉县',
              value: 4
            },
            {
              label: '开江县',
              value: 5
            },
            {
              label: '大竹县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '雅安市',
          value: 15,
          children: [
            {
              label: '雨城区',
              value: 0
            },
            {
              label: '芦山县',
              value: 1
            },
            {
              label: '石棉县',
              value: 2
            },
            {
              label: '名山县',
              value: 3
            },
            {
              label: '天全县',
              value: 4
            },
            {
              label: '荥经县',
              value: 5
            },
            {
              label: '宝兴县',
              value: 6
            },
            {
              label: '汉源县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '巴中市',
          value: 16,
          children: [
            {
              label: '巴州区',
              value: 0
            },
            {
              label: '南江县',
              value: 1
            },
            {
              label: '平昌县',
              value: 2
            },
            {
              label: '通江县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '资阳市',
          value: 17,
          children: [
            {
              label: '雁江区',
              value: 0
            },
            {
              label: '简阳市',
              value: 1
            },
            {
              label: '安岳县',
              value: 2
            },
            {
              label: '乐至县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '阿坝藏族羌族自治州',
          value: 18,
          children: [
            {
              label: '马尔康县',
              value: 0
            },
            {
              label: '九寨沟县',
              value: 1
            },
            {
              label: '红原县',
              value: 2
            },
            {
              label: '汶川县',
              value: 3
            },
            {
              label: '阿坝县',
              value: 4
            },
            {
              label: '理县',
              value: 5
            },
            {
              label: '若尔盖县',
              value: 6
            },
            {
              label: '小金县',
              value: 7
            },
            {
              label: '黑水县',
              value: 8
            },
            {
              label: '金川县',
              value: 9
            },
            {
              label: '松潘县',
              value: 10
            },
            {
              label: '壤塘县',
              value: 11
            },
            {
              label: '茂县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '甘孜藏族自治州',
          value: 19,
          children: [
            {
              label: '康定县',
              value: 0
            },
            {
              label: '丹巴县',
              value: 1
            },
            {
              label: '炉霍县',
              value: 2
            },
            {
              label: '九龙县',
              value: 3
            },
            {
              label: '甘孜县',
              value: 4
            },
            {
              label: '雅江县',
              value: 5
            },
            {
              label: '新龙县',
              value: 6
            },
            {
              label: '道孚县',
              value: 7
            },
            {
              label: '白玉县',
              value: 8
            },
            {
              label: '理塘县',
              value: 9
            },
            {
              label: '德格县',
              value: 10
            },
            {
              label: '乡城县',
              value: 11
            },
            {
              label: '石渠县',
              value: 12
            },
            {
              label: '稻城县',
              value: 13
            },
            {
              label: '色达县',
              value: 14
            },
            {
              label: '巴塘县',
              value: 15
            },
            {
              label: '泸定县',
              value: 16
            },
            {
              label: '得荣县',
              value: 17
            },
            {
              label: '其他',
              value: 18
            }
          ]
        },
        {
          label: '凉山彝族自治州',
          value: 20,
          children: [
            {
              label: '西昌市',
              value: 0
            },
            {
              label: '美姑县',
              value: 1
            },
            {
              label: '昭觉县',
              value: 2
            },
            {
              label: '金阳县',
              value: 3
            },
            {
              label: '甘洛县',
              value: 4
            },
            {
              label: '布拖县',
              value: 5
            },
            {
              label: '雷波县',
              value: 6
            },
            {
              label: '普格县',
              value: 7
            },
            {
              label: '宁南县',
              value: 8
            },
            {
              label: '喜德县',
              value: 9
            },
            {
              label: '会东县',
              value: 10
            },
            {
              label: '越西县',
              value: 11
            },
            {
              label: '会理县',
              value: 12
            },
            {
              label: '盐源县',
              value: 13
            },
            {
              label: '德昌县',
              value: 14
            },
            {
              label: '冕宁县',
              value: 15
            },
            {
              label: '木里藏族自治县',
              value: 16
            },
            {
              label: '其他',
              value: 17
            }
          ]
        },
        {
          label: '其他',
          value: 21,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '贵州省',
      value: 23,
      children: [
        {
          label: '贵阳市',
          value: 0,
          children: [
            {
              label: '南明区',
              value: 0
            },
            {
              label: '云岩区',
              value: 1
            },
            {
              label: '花溪区',
              value: 2
            },
            {
              label: '乌当区',
              value: 3
            },
            {
              label: '白云区',
              value: 4
            },
            {
              label: '小河区',
              value: 5
            },
            {
              label: '清镇市',
              value: 6
            },
            {
              label: '开阳县',
              value: 7
            },
            {
              label: '修文县',
              value: 8
            },
            {
              label: '息烽县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '六盘水市',
          value: 1,
          children: [
            {
              label: '钟山区',
              value: 0
            },
            {
              label: '水城县',
              value: 1
            },
            {
              label: '盘县',
              value: 2
            },
            {
              label: '六枝特区',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '遵义市',
          value: 2,
          children: [
            {
              label: '红花岗区',
              value: 0
            },
            {
              label: '汇川区',
              value: 1
            },
            {
              label: '赤水市',
              value: 2
            },
            {
              label: '仁怀市',
              value: 3
            },
            {
              label: '遵义县',
              value: 4
            },
            {
              label: '绥阳县',
              value: 5
            },
            {
              label: '桐梓县',
              value: 6
            },
            {
              label: '习水县',
              value: 7
            },
            {
              label: '凤冈县',
              value: 8
            },
            {
              label: '正安县',
              value: 9
            },
            {
              label: '余庆县',
              value: 10
            },
            {
              label: '湄潭县',
              value: 11
            },
            {
              label: '道真仡佬族苗族自治县',
              value: 12
            },
            {
              label: '务川仡佬族苗族自治县',
              value: 13
            },
            {
              label: '其他',
              value: 14
            }
          ]
        },
        {
          label: '安顺市',
          value: 3,
          children: [
            {
              label: '西秀区',
              value: 0
            },
            {
              label: '普定县',
              value: 1
            },
            {
              label: '平坝县',
              value: 2
            },
            {
              label: '镇宁布依族苗族自治县',
              value: 3
            },
            {
              label: '紫云苗族布依族自治县',
              value: 4
            },
            {
              label: '关岭布依族苗族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '铜仁地区',
          value: 4,
          children: [
            {
              label: '铜仁市',
              value: 0
            },
            {
              label: '德江县',
              value: 1
            },
            {
              label: '江口县',
              value: 2
            },
            {
              label: '思南县',
              value: 3
            },
            {
              label: '石阡县',
              value: 4
            },
            {
              label: '玉屏侗族自治县',
              value: 5
            },
            {
              label: '松桃苗族自治县',
              value: 6
            },
            {
              label: '印江土家族苗族自治县',
              value: 7
            },
            {
              label: '沿河土家族自治县',
              value: 8
            },
            {
              label: '万山特区',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '毕节地区',
          value: 5,
          children: [
            {
              label: '毕节市',
              value: 0
            },
            {
              label: '黔西县',
              value: 1
            },
            {
              label: '大方县',
              value: 2
            },
            {
              label: '织金县',
              value: 3
            },
            {
              label: '金沙县',
              value: 4
            },
            {
              label: '赫章县',
              value: 5
            },
            {
              label: '纳雍县',
              value: 6
            },
            {
              label: '威宁彝族回族苗族自治县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '黔西南布依族苗族自治州',
          value: 6,
          children: [
            {
              label: '兴义市',
              value: 0
            },
            {
              label: '望谟县',
              value: 1
            },
            {
              label: '兴仁县',
              value: 2
            },
            {
              label: '普安县',
              value: 3
            },
            {
              label: '册亨县',
              value: 4
            },
            {
              label: '晴隆县',
              value: 5
            },
            {
              label: '贞丰县',
              value: 6
            },
            {
              label: '安龙县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '黔东南苗族侗族自治州',
          value: 7,
          children: [
            {
              label: '凯里市',
              value: 0
            },
            {
              label: '施秉县',
              value: 1
            },
            {
              label: '从江县',
              value: 2
            },
            {
              label: '锦屏县',
              value: 3
            },
            {
              label: '镇远县',
              value: 4
            },
            {
              label: '麻江县',
              value: 5
            },
            {
              label: '台江县',
              value: 6
            },
            {
              label: '天柱县',
              value: 7
            },
            {
              label: '黄平县',
              value: 8
            },
            {
              label: '榕江县',
              value: 9
            },
            {
              label: '剑河县',
              value: 10
            },
            {
              label: '三穗县',
              value: 11
            },
            {
              label: '雷山县',
              value: 12
            },
            {
              label: '黎平县',
              value: 13
            },
            {
              label: '岑巩县',
              value: 14
            },
            {
              label: '丹寨县',
              value: 15
            },
            {
              label: '其他',
              value: 16
            }
          ]
        },
        {
          label: '黔南布依族苗族自治州',
          value: 8,
          children: [
            {
              label: '都匀市',
              value: 0
            },
            {
              label: '福泉市',
              value: 1
            },
            {
              label: '贵定县',
              value: 2
            },
            {
              label: '惠水县',
              value: 3
            },
            {
              label: '罗甸县',
              value: 4
            },
            {
              label: '瓮安县',
              value: 5
            },
            {
              label: '荔波县',
              value: 6
            },
            {
              label: '龙里县',
              value: 7
            },
            {
              label: '平塘县',
              value: 8
            },
            {
              label: '长顺县',
              value: 9
            },
            {
              label: '独山县',
              value: 10
            },
            {
              label: '三都水族自治县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '其他',
          value: 9,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '云南省',
      value: 24,
      children: [
        {
          label: '昆明市',
          value: 0,
          children: [
            {
              label: '盘龙区',
              value: 0
            },
            {
              label: '五华区',
              value: 1
            },
            {
              label: '官渡区',
              value: 2
            },
            {
              label: '西山区',
              value: 3
            },
            {
              label: '东川区',
              value: 4
            },
            {
              label: '安宁市',
              value: 5
            },
            {
              label: '呈贡县',
              value: 6
            },
            {
              label: '晋宁县',
              value: 7
            },
            {
              label: '富民县',
              value: 8
            },
            {
              label: '宜良县',
              value: 9
            },
            {
              label: '嵩明县',
              value: 10
            },
            {
              label: '石林彝族自治县',
              value: 11
            },
            {
              label: '禄劝彝族苗族自治县',
              value: 12
            },
            {
              label: '寻甸回族彝族自治县',
              value: 13
            },
            {
              label: '其他',
              value: 14
            }
          ]
        },
        {
          label: '曲靖市',
          value: 1,
          children: [
            {
              label: '麒麟区',
              value: 0
            },
            {
              label: '宣威市',
              value: 1
            },
            {
              label: '马龙县',
              value: 2
            },
            {
              label: '沾益县',
              value: 3
            },
            {
              label: '富源县',
              value: 4
            },
            {
              label: '罗平县',
              value: 5
            },
            {
              label: '师宗县',
              value: 6
            },
            {
              label: '陆良县',
              value: 7
            },
            {
              label: '会泽县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '玉溪市',
          value: 2,
          children: [
            {
              label: '红塔区',
              value: 0
            },
            {
              label: '江川县',
              value: 1
            },
            {
              label: '澄江县',
              value: 2
            },
            {
              label: '通海县',
              value: 3
            },
            {
              label: '华宁县',
              value: 4
            },
            {
              label: '易门县',
              value: 5
            },
            {
              label: '峨山彝族自治县',
              value: 6
            },
            {
              label: '新平彝族傣族自治县',
              value: 7
            },
            {
              label: '元江哈尼族彝族傣族自治县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '保山市',
          value: 3,
          children: [
            {
              label: '隆阳区',
              value: 0
            },
            {
              label: '施甸县',
              value: 1
            },
            {
              label: '腾冲县',
              value: 2
            },
            {
              label: '龙陵县',
              value: 3
            },
            {
              label: '昌宁县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '昭通市',
          value: 4,
          children: [
            {
              label: '昭阳区',
              value: 0
            },
            {
              label: '鲁甸县',
              value: 1
            },
            {
              label: '巧家县',
              value: 2
            },
            {
              label: '盐津县',
              value: 3
            },
            {
              label: '大关县',
              value: 4
            },
            {
              label: '永善县',
              value: 5
            },
            {
              label: '绥江县',
              value: 6
            },
            {
              label: '镇雄县',
              value: 7
            },
            {
              label: '彝良县',
              value: 8
            },
            {
              label: '威信县',
              value: 9
            },
            {
              label: '水富县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '丽江市',
          value: 5,
          children: [
            {
              label: '古城区',
              value: 0
            },
            {
              label: '永胜县',
              value: 1
            },
            {
              label: '华坪县',
              value: 2
            },
            {
              label: '玉龙纳西族自治县',
              value: 3
            },
            {
              label: '宁蒗彝族自治县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '普洱市',
          value: 6,
          children: [
            {
              label: '思茅区',
              value: 0
            },
            {
              label: '普洱哈尼族彝族自治县',
              value: 1
            },
            {
              label: '墨江哈尼族自治县',
              value: 2
            },
            {
              label: '景东彝族自治县',
              value: 3
            },
            {
              label: '景谷傣族彝族自治县',
              value: 4
            },
            {
              label: '镇沅彝族哈尼族拉祜族自治县',
              value: 5
            },
            {
              label: '江城哈尼族彝族自治县',
              value: 6
            },
            {
              label: '孟连傣族拉祜族佤族自治县',
              value: 7
            },
            {
              label: '澜沧拉祜族自治县',
              value: 8
            },
            {
              label: '西盟佤族自治县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '临沧市',
          value: 7,
          children: [
            {
              label: '临翔区',
              value: 0
            },
            {
              label: '凤庆县',
              value: 1
            },
            {
              label: '云县',
              value: 2
            },
            {
              label: '永德县',
              value: 3
            },
            {
              label: '镇康县',
              value: 4
            },
            {
              label: '双江拉祜族佤族布朗族傣族自治县',
              value: 5
            },
            {
              label: '耿马傣族佤族自治县',
              value: 6
            },
            {
              label: '沧源佤族自治县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '德宏傣族景颇族自治州',
          value: 8,
          children: [
            {
              label: '潞西市',
              value: 0
            },
            {
              label: '瑞丽市',
              value: 1
            },
            {
              label: '梁河县',
              value: 2
            },
            {
              label: '盈江县',
              value: 3
            },
            {
              label: '陇川县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '怒江傈僳族自治州',
          value: 9,
          children: [
            {
              label: '泸水县',
              value: 0
            },
            {
              label: '福贡县',
              value: 1
            },
            {
              label: '贡山独龙族怒族自治县',
              value: 2
            },
            {
              label: '兰坪白族普米族自治县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '迪庆藏族自治州',
          value: 10,
          children: [
            {
              label: '香格里拉县',
              value: 0
            },
            {
              label: '德钦县',
              value: 1
            },
            {
              label: '维西傈僳族自治县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '大理白族自治州',
          value: 11,
          children: [
            {
              label: '大理市',
              value: 0
            },
            {
              label: '祥云县',
              value: 1
            },
            {
              label: '宾川县',
              value: 2
            },
            {
              label: '弥渡县',
              value: 3
            },
            {
              label: '永平县',
              value: 4
            },
            {
              label: '云龙县',
              value: 5
            },
            {
              label: '洱源县',
              value: 6
            },
            {
              label: '剑川县',
              value: 7
            },
            {
              label: '鹤庆县',
              value: 8
            },
            {
              label: '漾濞彝族自治县',
              value: 9
            },
            {
              label: '南涧彝族自治县',
              value: 10
            },
            {
              label: '巍山彝族回族自治县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '楚雄彝族自治州',
          value: 12,
          children: [
            {
              label: '楚雄市',
              value: 0
            },
            {
              label: '双柏县',
              value: 1
            },
            {
              label: '牟定县',
              value: 2
            },
            {
              label: '南华县',
              value: 3
            },
            {
              label: '姚安县',
              value: 4
            },
            {
              label: '大姚县',
              value: 5
            },
            {
              label: '永仁县',
              value: 6
            },
            {
              label: '元谋县',
              value: 7
            },
            {
              label: '武定县',
              value: 8
            },
            {
              label: '禄丰县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '红河哈尼族彝族自治州',
          value: 13,
          children: [
            {
              label: '蒙自县',
              value: 0
            },
            {
              label: '个旧市',
              value: 1
            },
            {
              label: '开远市',
              value: 2
            },
            {
              label: '绿春县',
              value: 3
            },
            {
              label: '建水县',
              value: 4
            },
            {
              label: '石屏县',
              value: 5
            },
            {
              label: '弥勒县',
              value: 6
            },
            {
              label: '泸西县',
              value: 7
            },
            {
              label: '元阳县',
              value: 8
            },
            {
              label: '红河县',
              value: 9
            },
            {
              label: '金平苗族瑶族傣族自治县',
              value: 10
            },
            {
              label: '河口瑶族自治县',
              value: 11
            },
            {
              label: '屏边苗族自治县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '文山壮族苗族自治州',
          value: 14,
          children: [
            {
              label: '文山县',
              value: 0
            },
            {
              label: '砚山县',
              value: 1
            },
            {
              label: '西畴县',
              value: 2
            },
            {
              label: '麻栗坡县',
              value: 3
            },
            {
              label: '马关县',
              value: 4
            },
            {
              label: '丘北县',
              value: 5
            },
            {
              label: '广南县',
              value: 6
            },
            {
              label: '富宁县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '西双版纳傣族自治州',
          value: 15,
          children: [
            {
              label: '景洪市',
              value: 0
            },
            {
              label: '勐海县',
              value: 1
            },
            {
              label: '勐腊县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '其他',
          value: 16,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '西藏',
      value: 25,
      children: [
        {
          label: '拉萨市',
          value: 0,
          children: [
            {
              label: '城关区',
              value: 0
            },
            {
              label: '林周县',
              value: 1
            },
            {
              label: '当雄县',
              value: 2
            },
            {
              label: '尼木县',
              value: 3
            },
            {
              label: '曲水县',
              value: 4
            },
            {
              label: '堆龙德庆县',
              value: 5
            },
            {
              label: '达孜县',
              value: 6
            },
            {
              label: '墨竹工卡县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '那曲地区',
          value: 1,
          children: [
            {
              label: '那曲县',
              value: 0
            },
            {
              label: '嘉黎县',
              value: 1
            },
            {
              label: '比如县',
              value: 2
            },
            {
              label: '聂荣县',
              value: 3
            },
            {
              label: '安多县',
              value: 4
            },
            {
              label: '申扎县',
              value: 5
            },
            {
              label: '索县',
              value: 6
            },
            {
              label: '班戈县',
              value: 7
            },
            {
              label: '巴青县',
              value: 8
            },
            {
              label: '尼玛县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '昌都地区',
          value: 2,
          children: [
            {
              label: '昌都县',
              value: 0
            },
            {
              label: '江达县',
              value: 1
            },
            {
              label: '贡觉县',
              value: 2
            },
            {
              label: '类乌齐县',
              value: 3
            },
            {
              label: '丁青县',
              value: 4
            },
            {
              label: '察雅县',
              value: 5
            },
            {
              label: '八宿县',
              value: 6
            },
            {
              label: '左贡县',
              value: 7
            },
            {
              label: '芒康县',
              value: 8
            },
            {
              label: '洛隆县',
              value: 9
            },
            {
              label: '边坝县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '林芝地区',
          value: 3,
          children: [
            {
              label: '林芝县',
              value: 0
            },
            {
              label: '工布江达县',
              value: 1
            },
            {
              label: '米林县',
              value: 2
            },
            {
              label: '墨脱县',
              value: 3
            },
            {
              label: '波密县',
              value: 4
            },
            {
              label: '察隅县',
              value: 5
            },
            {
              label: '朗县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '山南地区',
          value: 4,
          children: [
            {
              label: '乃东县',
              value: 0
            },
            {
              label: '扎囊县',
              value: 1
            },
            {
              label: '贡嘎县',
              value: 2
            },
            {
              label: '桑日县',
              value: 3
            },
            {
              label: '琼结县',
              value: 4
            },
            {
              label: '曲松县',
              value: 5
            },
            {
              label: '措美县',
              value: 6
            },
            {
              label: '洛扎县',
              value: 7
            },
            {
              label: '加查县',
              value: 8
            },
            {
              label: '隆子县',
              value: 9
            },
            {
              label: '错那县',
              value: 10
            },
            {
              label: '浪卡子县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '日喀则地区',
          value: 5,
          children: [
            {
              label: '日喀则市',
              value: 0
            },
            {
              label: '南木林县',
              value: 1
            },
            {
              label: '江孜县',
              value: 2
            },
            {
              label: '定日县',
              value: 3
            },
            {
              label: '萨迦县',
              value: 4
            },
            {
              label: '拉孜县',
              value: 5
            },
            {
              label: '昂仁县',
              value: 6
            },
            {
              label: '谢通门县',
              value: 7
            },
            {
              label: '白朗县',
              value: 8
            },
            {
              label: '仁布县',
              value: 9
            },
            {
              label: '康马县',
              value: 10
            },
            {
              label: '定结县',
              value: 11
            },
            {
              label: '仲巴县',
              value: 12
            },
            {
              label: '亚东县',
              value: 13
            },
            {
              label: '吉隆县',
              value: 14
            },
            {
              label: '聂拉木县',
              value: 15
            },
            {
              label: '萨嘎县',
              value: 16
            },
            {
              label: '岗巴县',
              value: 17
            },
            {
              label: '其他',
              value: 18
            }
          ]
        },
        {
          label: '阿里地区',
          value: 6,
          children: [
            {
              label: '噶尔县',
              value: 0
            },
            {
              label: '普兰县',
              value: 1
            },
            {
              label: '札达县',
              value: 2
            },
            {
              label: '日土县',
              value: 3
            },
            {
              label: '革吉县',
              value: 4
            },
            {
              label: '改则县',
              value: 5
            },
            {
              label: '措勤县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '其他',
          value: 7,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '陕西省',
      value: 26,
      children: [
        {
          label: '西安市',
          value: 0,
          children: [
            {
              label: '莲湖区',
              value: 0
            },
            {
              label: '新城区',
              value: 1
            },
            {
              label: '碑林区',
              value: 2
            },
            {
              label: '雁塔区',
              value: 3
            },
            {
              label: '灞桥区',
              value: 4
            },
            {
              label: '未央区',
              value: 5
            },
            {
              label: '阎良区',
              value: 6
            },
            {
              label: '临潼区',
              value: 7
            },
            {
              label: '长安区',
              value: 8
            },
            {
              label: '高陵县',
              value: 9
            },
            {
              label: '蓝田县',
              value: 10
            },
            {
              label: '户县',
              value: 11
            },
            {
              label: '周至县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '铜川市',
          value: 1,
          children: [
            {
              label: '耀州区',
              value: 0
            },
            {
              label: '王益区',
              value: 1
            },
            {
              label: '印台区',
              value: 2
            },
            {
              label: '宜君县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '宝鸡市',
          value: 2,
          children: [
            {
              label: '渭滨区',
              value: 0
            },
            {
              label: '金台区',
              value: 1
            },
            {
              label: '陈仓区',
              value: 2
            },
            {
              label: '岐山县',
              value: 3
            },
            {
              label: '凤翔县',
              value: 4
            },
            {
              label: '陇县',
              value: 5
            },
            {
              label: '太白县',
              value: 6
            },
            {
              label: '麟游县',
              value: 7
            },
            {
              label: '扶风县',
              value: 8
            },
            {
              label: '千阳县',
              value: 9
            },
            {
              label: '眉县',
              value: 10
            },
            {
              label: '凤县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '咸阳市',
          value: 3,
          children: [
            {
              label: '秦都区',
              value: 0
            },
            {
              label: '渭城区',
              value: 1
            },
            {
              label: '杨陵区',
              value: 2
            },
            {
              label: '兴平市',
              value: 3
            },
            {
              label: '礼泉县',
              value: 4
            },
            {
              label: '泾阳县',
              value: 5
            },
            {
              label: '永寿县',
              value: 6
            },
            {
              label: '三原县',
              value: 7
            },
            {
              label: '彬县',
              value: 8
            },
            {
              label: '旬邑县',
              value: 9
            },
            {
              label: '长武县',
              value: 10
            },
            {
              label: '乾县',
              value: 11
            },
            {
              label: '武功县',
              value: 12
            },
            {
              label: '淳化县',
              value: 13
            },
            {
              label: '其他',
              value: 14
            }
          ]
        },
        {
          label: '渭南市',
          value: 4,
          children: [
            {
              label: '临渭区',
              value: 0
            },
            {
              label: '韩城市',
              value: 1
            },
            {
              label: '华阴市',
              value: 2
            },
            {
              label: '蒲城县',
              value: 3
            },
            {
              label: '潼关县',
              value: 4
            },
            {
              label: '白水县',
              value: 5
            },
            {
              label: '澄城县',
              value: 6
            },
            {
              label: '华县',
              value: 7
            },
            {
              label: '合阳县',
              value: 8
            },
            {
              label: '富平县',
              value: 9
            },
            {
              label: '大荔县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '延安市',
          value: 5,
          children: [
            {
              label: '宝塔区',
              value: 0
            },
            {
              label: '安塞县',
              value: 1
            },
            {
              label: '洛川县',
              value: 2
            },
            {
              label: '子长县',
              value: 3
            },
            {
              label: '黄陵县',
              value: 4
            },
            {
              label: '延川县',
              value: 5
            },
            {
              label: '富县',
              value: 6
            },
            {
              label: '延长县',
              value: 7
            },
            {
              label: '甘泉县',
              value: 8
            },
            {
              label: '宜川县',
              value: 9
            },
            {
              label: '志丹县',
              value: 10
            },
            {
              label: '黄龙县',
              value: 11
            },
            {
              label: '吴起县',
              value: 12
            },
            {
              label: '其他',
              value: 13
            }
          ]
        },
        {
          label: '汉中市',
          value: 6,
          children: [
            {
              label: '汉台区',
              value: 0
            },
            {
              label: '留坝县',
              value: 1
            },
            {
              label: '镇巴县',
              value: 2
            },
            {
              label: '城固县',
              value: 3
            },
            {
              label: '南郑县',
              value: 4
            },
            {
              label: '洋县',
              value: 5
            },
            {
              label: '宁强县',
              value: 6
            },
            {
              label: '佛坪县',
              value: 7
            },
            {
              label: '勉县',
              value: 8
            },
            {
              label: '西乡县',
              value: 9
            },
            {
              label: '略阳县',
              value: 10
            },
            {
              label: '其他',
              value: 11
            }
          ]
        },
        {
          label: '榆林市',
          value: 7,
          children: [
            {
              label: '榆阳区',
              value: 0
            },
            {
              label: '清涧县',
              value: 1
            },
            {
              label: '绥德县',
              value: 2
            },
            {
              label: '神木县',
              value: 3
            },
            {
              label: '佳县',
              value: 4
            },
            {
              label: '府谷县',
              value: 5
            },
            {
              label: '子洲县',
              value: 6
            },
            {
              label: '靖边县',
              value: 7
            },
            {
              label: '横山县',
              value: 8
            },
            {
              label: '米脂县',
              value: 9
            },
            {
              label: '吴堡县',
              value: 10
            },
            {
              label: '定边县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '安康市',
          value: 8,
          children: [
            {
              label: '汉滨区',
              value: 0
            },
            {
              label: '紫阳县',
              value: 1
            },
            {
              label: '岚皋县',
              value: 2
            },
            {
              label: '旬阳县',
              value: 3
            },
            {
              label: '镇坪县',
              value: 4
            },
            {
              label: '平利县',
              value: 5
            },
            {
              label: '石泉县',
              value: 6
            },
            {
              label: '宁陕县',
              value: 7
            },
            {
              label: '白河县',
              value: 8
            },
            {
              label: '汉阴县',
              value: 9
            },
            {
              label: '其他',
              value: 10
            }
          ]
        },
        {
          label: '商洛市',
          value: 9,
          children: [
            {
              label: '商州区',
              value: 0
            },
            {
              label: '镇安县',
              value: 1
            },
            {
              label: '山阳县',
              value: 2
            },
            {
              label: '洛南县',
              value: 3
            },
            {
              label: '商南县',
              value: 4
            },
            {
              label: '丹凤县',
              value: 5
            },
            {
              label: '柞水县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '其他',
          value: 10,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '甘肃省',
      value: 27,
      children: [
        {
          label: '兰州市',
          value: 0,
          children: [
            {
              label: '城关区',
              value: 0
            },
            {
              label: '七里河区',
              value: 1
            },
            {
              label: '西固区',
              value: 2
            },
            {
              label: '安宁区',
              value: 3
            },
            {
              label: '红古区',
              value: 4
            },
            {
              label: '永登县',
              value: 5
            },
            {
              label: '皋兰县',
              value: 6
            },
            {
              label: '榆中县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '嘉峪关市',
          value: 1,
          children: [
            {
              label: '嘉峪关市',
              value: 0
            },
            {
              label: '其他',
              value: 1
            }
          ]
        },
        {
          label: '金昌市',
          value: 2,
          children: [
            {
              label: '金川区',
              value: 0
            },
            {
              label: '永昌县',
              value: 1
            },
            {
              label: '其他',
              value: 2
            }
          ]
        },
        {
          label: '白银市',
          value: 3,
          children: [
            {
              label: '白银区',
              value: 0
            },
            {
              label: '平川区',
              value: 1
            },
            {
              label: '靖远县',
              value: 2
            },
            {
              label: '会宁县',
              value: 3
            },
            {
              label: '景泰县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '天水市',
          value: 4,
          children: [
            {
              label: '清水县',
              value: 0
            },
            {
              label: '秦安县',
              value: 1
            },
            {
              label: '甘谷县',
              value: 2
            },
            {
              label: '武山县',
              value: 3
            },
            {
              label: '张家川回族自治县',
              value: 4
            },
            {
              label: '北道区',
              value: 5
            },
            {
              label: '秦城区',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '武威市',
          value: 5,
          children: [
            {
              label: '凉州区',
              value: 0
            },
            {
              label: '民勤县',
              value: 1
            },
            {
              label: '古浪县',
              value: 2
            },
            {
              label: '天祝藏族自治县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '酒泉市',
          value: 6,
          children: [
            {
              label: '肃州区',
              value: 0
            },
            {
              label: '玉门市',
              value: 1
            },
            {
              label: '敦煌市',
              value: 2
            },
            {
              label: '金塔县',
              value: 3
            },
            {
              label: '肃北蒙古族自治县',
              value: 4
            },
            {
              label: '阿克塞哈萨克族自治县',
              value: 5
            },
            {
              label: '安西县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '张掖市',
          value: 7,
          children: [
            {
              label: '甘州区',
              value: 0
            },
            {
              label: '民乐县',
              value: 1
            },
            {
              label: '临泽县',
              value: 2
            },
            {
              label: '高台县',
              value: 3
            },
            {
              label: '山丹县',
              value: 4
            },
            {
              label: '肃南裕固族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '庆阳市',
          value: 8,
          children: [
            {
              label: '西峰区',
              value: 0
            },
            {
              label: '庆城县',
              value: 1
            },
            {
              label: '环县',
              value: 2
            },
            {
              label: '华池县',
              value: 3
            },
            {
              label: '合水县',
              value: 4
            },
            {
              label: '正宁县',
              value: 5
            },
            {
              label: '宁县',
              value: 6
            },
            {
              label: '镇原县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '平凉市',
          value: 9,
          children: [
            {
              label: '崆峒区',
              value: 0
            },
            {
              label: '泾川县',
              value: 1
            },
            {
              label: '灵台县',
              value: 2
            },
            {
              label: '崇信县',
              value: 3
            },
            {
              label: '华亭县',
              value: 4
            },
            {
              label: '庄浪县',
              value: 5
            },
            {
              label: '静宁县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '定西市',
          value: 10,
          children: [
            {
              label: '安定区',
              value: 0
            },
            {
              label: '通渭县',
              value: 1
            },
            {
              label: '临洮县',
              value: 2
            },
            {
              label: '漳县',
              value: 3
            },
            {
              label: '岷县',
              value: 4
            },
            {
              label: '渭源县',
              value: 5
            },
            {
              label: '陇西县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '陇南市',
          value: 11,
          children: [
            {
              label: '武都区',
              value: 0
            },
            {
              label: '成县',
              value: 1
            },
            {
              label: '宕昌县',
              value: 2
            },
            {
              label: '康县',
              value: 3
            },
            {
              label: '文县',
              value: 4
            },
            {
              label: '西和县',
              value: 5
            },
            {
              label: '礼县',
              value: 6
            },
            {
              label: '两当县',
              value: 7
            },
            {
              label: '徽县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '临夏回族自治州',
          value: 12,
          children: [
            {
              label: '临夏市',
              value: 0
            },
            {
              label: '临夏县',
              value: 1
            },
            {
              label: '康乐县',
              value: 2
            },
            {
              label: '永靖县',
              value: 3
            },
            {
              label: '广河县',
              value: 4
            },
            {
              label: '和政县',
              value: 5
            },
            {
              label: '东乡族自治县',
              value: 6
            },
            {
              label: '积石山保安族东乡族撒拉族自治县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '甘南藏族自治州',
          value: 13,
          children: [
            {
              label: '合作市',
              value: 0
            },
            {
              label: '临潭县',
              value: 1
            },
            {
              label: '卓尼县',
              value: 2
            },
            {
              label: '舟曲县',
              value: 3
            },
            {
              label: '迭部县',
              value: 4
            },
            {
              label: '玛曲县',
              value: 5
            },
            {
              label: '碌曲县',
              value: 6
            },
            {
              label: '夏河县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '其他',
          value: 14,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '青海省',
      value: 28,
      children: [
        {
          label: '西宁市',
          value: 0,
          children: [
            {
              label: '城中区',
              value: 0
            },
            {
              label: '城东区',
              value: 1
            },
            {
              label: '城西区',
              value: 2
            },
            {
              label: '城北区',
              value: 3
            },
            {
              label: '湟源县',
              value: 4
            },
            {
              label: '湟中县',
              value: 5
            },
            {
              label: '大通回族土族自治县',
              value: 6
            },
            {
              label: '其他',
              value: 7
            }
          ]
        },
        {
          label: '海东地区',
          value: 1,
          children: [
            {
              label: '平安县',
              value: 0
            },
            {
              label: '乐都县',
              value: 1
            },
            {
              label: '民和回族土族自治县',
              value: 2
            },
            {
              label: '互助土族自治县',
              value: 3
            },
            {
              label: '化隆回族自治县',
              value: 4
            },
            {
              label: '循化撒拉族自治县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '海北藏族自治州',
          value: 2,
          children: [
            {
              label: '海晏县',
              value: 0
            },
            {
              label: '祁连县',
              value: 1
            },
            {
              label: '刚察县',
              value: 2
            },
            {
              label: '门源回族自治县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '海南藏族自治州',
          value: 3,
          children: [
            {
              label: '共和县',
              value: 0
            },
            {
              label: '同德县',
              value: 1
            },
            {
              label: '贵德县',
              value: 2
            },
            {
              label: '兴海县',
              value: 3
            },
            {
              label: '贵南县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '黄南藏族自治州',
          value: 4,
          children: [
            {
              label: '同仁县',
              value: 0
            },
            {
              label: '尖扎县',
              value: 1
            },
            {
              label: '泽库县',
              value: 2
            },
            {
              label: '河南蒙古族自治县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '果洛藏族自治州',
          value: 5,
          children: [
            {
              label: '玛沁县',
              value: 0
            },
            {
              label: '班玛县',
              value: 1
            },
            {
              label: '甘德县',
              value: 2
            },
            {
              label: '达日县',
              value: 3
            },
            {
              label: '久治县',
              value: 4
            },
            {
              label: '玛多县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '玉树藏族自治州',
          value: 6,
          children: [
            {
              label: '玉树县',
              value: 0
            },
            {
              label: '杂多县',
              value: 1
            },
            {
              label: '称多县',
              value: 2
            },
            {
              label: '治多县',
              value: 3
            },
            {
              label: '囊谦县',
              value: 4
            },
            {
              label: '曲麻莱县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '海西蒙古族藏族自治州',
          value: 7,
          children: [
            {
              label: '德令哈市',
              value: 0
            },
            {
              label: '格尔木市',
              value: 1
            },
            {
              label: '乌兰县',
              value: 2
            },
            {
              label: '都兰县',
              value: 3
            },
            {
              label: '天峻县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '其他',
          value: 8,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '宁夏',
      value: 29,
      children: [
        {
          label: '银川市',
          value: 0,
          children: [
            {
              label: '兴庆区',
              value: 0
            },
            {
              label: '西夏区',
              value: 1
            },
            {
              label: '金凤区',
              value: 2
            },
            {
              label: '灵武市',
              value: 3
            },
            {
              label: '永宁县',
              value: 4
            },
            {
              label: '贺兰县',
              value: 5
            },
            {
              label: '其他',
              value: 6
            }
          ]
        },
        {
          label: '石嘴山市',
          value: 1,
          children: [
            {
              label: '大武口区',
              value: 0
            },
            {
              label: '惠农区',
              value: 1
            },
            {
              label: '平罗县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '吴忠市',
          value: 2,
          children: [
            {
              label: '利通区',
              value: 0
            },
            {
              label: '青铜峡市',
              value: 1
            },
            {
              label: '盐池县',
              value: 2
            },
            {
              label: '同心县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '固原市',
          value: 3,
          children: [
            {
              label: '原州区',
              value: 0
            },
            {
              label: '西吉县',
              value: 1
            },
            {
              label: '隆德县',
              value: 2
            },
            {
              label: '泾源县',
              value: 3
            },
            {
              label: '彭阳县',
              value: 4
            },
            {
              label: '其他',
              value: 5
            }
          ]
        },
        {
          label: '中卫市',
          value: 4,
          children: [
            {
              label: '沙坡头区',
              value: 0
            },
            {
              label: '中宁县',
              value: 1
            },
            {
              label: '海原县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '其他',
          value: 5,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '新疆',
      value: 30,
      children: [
        {
          label: '乌鲁木齐市',
          value: 0,
          children: [
            {
              label: '天山区',
              value: 0
            },
            {
              label: '沙依巴克区',
              value: 1
            },
            {
              label: '新市区',
              value: 2
            },
            {
              label: '水磨沟区',
              value: 3
            },
            {
              label: '头屯河区',
              value: 4
            },
            {
              label: '达坂城区',
              value: 5
            },
            {
              label: '东山区',
              value: 6
            },
            {
              label: '乌鲁木齐县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '克拉玛依市',
          value: 1,
          children: [
            {
              label: '克拉玛依区',
              value: 0
            },
            {
              label: '独山子区',
              value: 1
            },
            {
              label: '白碱滩区',
              value: 2
            },
            {
              label: '乌尔禾区',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '吐鲁番地区',
          value: 2,
          children: [
            {
              label: '吐鲁番市',
              value: 0
            },
            {
              label: '托克逊县',
              value: 1
            },
            {
              label: '鄯善县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '哈密地区',
          value: 3,
          children: [
            {
              label: '哈密市',
              value: 0
            },
            {
              label: '伊吾县',
              value: 1
            },
            {
              label: '巴里坤哈萨克自治县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '和田地区',
          value: 4,
          children: [
            {
              label: '和田市',
              value: 0
            },
            {
              label: '和田县',
              value: 1
            },
            {
              label: '洛浦县',
              value: 2
            },
            {
              label: '民丰县',
              value: 3
            },
            {
              label: '皮山县',
              value: 4
            },
            {
              label: '策勒县',
              value: 5
            },
            {
              label: '于田县',
              value: 6
            },
            {
              label: '墨玉县',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '阿克苏地区',
          value: 5,
          children: [
            {
              label: '阿克苏市',
              value: 0
            },
            {
              label: '温宿县',
              value: 1
            },
            {
              label: '沙雅县',
              value: 2
            },
            {
              label: '拜城县',
              value: 3
            },
            {
              label: '阿瓦提县',
              value: 4
            },
            {
              label: '库车县',
              value: 5
            },
            {
              label: '柯坪县',
              value: 6
            },
            {
              label: '新和县',
              value: 7
            },
            {
              label: '乌什县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '喀什地区',
          value: 6,
          children: [
            {
              label: '喀什市',
              value: 0
            },
            {
              label: '巴楚县',
              value: 1
            },
            {
              label: '泽普县',
              value: 2
            },
            {
              label: '伽师县',
              value: 3
            },
            {
              label: '叶城县',
              value: 4
            },
            {
              label: '岳普湖县',
              value: 5
            },
            {
              label: '疏勒县',
              value: 6
            },
            {
              label: '麦盖提县',
              value: 7
            },
            {
              label: '英吉沙县',
              value: 8
            },
            {
              label: '莎车县',
              value: 9
            },
            {
              label: '疏附县',
              value: 10
            },
            {
              label: '塔什库尔干塔吉克自治县',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '克孜勒苏柯尔克孜自治州',
          value: 7,
          children: [
            {
              label: '阿图什市',
              value: 0
            },
            {
              label: '阿合奇县',
              value: 1
            },
            {
              label: '乌恰县',
              value: 2
            },
            {
              label: '阿克陶县',
              value: 3
            },
            {
              label: '其他',
              value: 4
            }
          ]
        },
        {
          label: '巴音郭楞蒙古自治州',
          value: 8,
          children: [
            {
              label: '库尔勒市',
              value: 0
            },
            {
              label: '和静县',
              value: 1
            },
            {
              label: '尉犁县',
              value: 2
            },
            {
              label: '和硕县',
              value: 3
            },
            {
              label: '且末县',
              value: 4
            },
            {
              label: '博湖县',
              value: 5
            },
            {
              label: '轮台县',
              value: 6
            },
            {
              label: '若羌县',
              value: 7
            },
            {
              label: '焉耆回族自治县',
              value: 8
            },
            {
              label: '其他',
              value: 9
            }
          ]
        },
        {
          label: '昌吉回族自治州',
          value: 9,
          children: [
            {
              label: '昌吉市',
              value: 0
            },
            {
              label: '阜康市',
              value: 1
            },
            {
              label: '奇台县',
              value: 2
            },
            {
              label: '玛纳斯县',
              value: 3
            },
            {
              label: '吉木萨尔县',
              value: 4
            },
            {
              label: '呼图壁县',
              value: 5
            },
            {
              label: '木垒哈萨克自治县',
              value: 6
            },
            {
              label: '米泉市',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        },
        {
          label: '博尔塔拉蒙古自治州',
          value: 10,
          children: [
            {
              label: '博乐市',
              value: 0
            },
            {
              label: '精河县',
              value: 1
            },
            {
              label: '温泉县',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '石河子',
          value: 11,
          children: [
            {
              label: '石河子',
              value: 0
            }
          ]
        },
        {
          label: '阿拉尔',
          value: 12,
          children: [
            {
              label: '阿拉尔',
              value: 0
            }
          ]
        },
        {
          label: '图木舒克',
          value: 13,
          children: [
            {
              label: '图木舒克',
              value: 0
            }
          ]
        },
        {
          label: '五家渠',
          value: 14,
          children: [
            {
              label: '五家渠',
              value: 0
            }
          ]
        },
        {
          label: '伊犁哈萨克自治州',
          value: 15,
          children: [
            {
              label: '伊宁市',
              value: 0
            },
            {
              label: '奎屯市',
              value: 1
            },
            {
              label: '伊宁县',
              value: 2
            },
            {
              label: '特克斯县',
              value: 3
            },
            {
              label: '尼勒克县',
              value: 4
            },
            {
              label: '昭苏县',
              value: 5
            },
            {
              label: '新源县',
              value: 6
            },
            {
              label: '霍城县',
              value: 7
            },
            {
              label: '巩留县',
              value: 8
            },
            {
              label: '察布查尔锡伯自治县',
              value: 9
            },
            {
              label: '塔城地区',
              value: 10
            },
            {
              label: '阿勒泰地区',
              value: 11
            },
            {
              label: '其他',
              value: 12
            }
          ]
        },
        {
          label: '其他',
          value: 16,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '台湾省',
      value: 31,
      children: [
        {
          label: '台北市',
          value: 0,
          children: [
            {
              label: '内湖区',
              value: 0
            },
            {
              label: '南港区',
              value: 1
            },
            {
              label: '中正区',
              value: 2
            },
            {
              label: '万华区',
              value: 3
            },
            {
              label: '大同区',
              value: 4
            },
            {
              label: '中山区',
              value: 5
            },
            {
              label: '松山区',
              value: 6
            },
            {
              label: '大安区',
              value: 7
            },
            {
              label: '信义区',
              value: 8
            },
            {
              label: '文山区',
              value: 9
            },
            {
              label: '士林区',
              value: 10
            },
            {
              label: '北投区',
              value: 11
            }
          ]
        },
        {
          label: '新北市',
          value: 1,
          children: [
            {
              label: '板桥区',
              value: 0
            },
            {
              label: '汐止区',
              value: 1
            },
            {
              label: '新店区',
              value: 2
            },
            {
              label: '其他',
              value: 3
            }
          ]
        },
        {
          label: '桃园市',
          value: 2,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        },
        {
          label: '台中市',
          value: 3,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        },
        {
          label: '台南市',
          value: 4,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        },
        {
          label: '高雄市',
          value: 5,
          children: [
            {
              label: '其他',
              value: 0
            }
          ]
        }
      ]
    },
    {
      label: '澳门',
      value: 32,
      children: [
        {
          label: '澳门',
          value: 0,
          children: [
            {
              label: '花地玛堂区',
              value: 0
            },
            {
              label: '圣安多尼堂区',
              value: 1
            },
            {
              label: '大堂区',
              value: 2
            },
            {
              label: '望德堂区',
              value: 3
            },
            {
              label: '风顺堂区',
              value: 4
            },
            {
              label: '嘉模堂区',
              value: 5
            },
            {
              label: '圣方济各堂区',
              value: 6
            },
            {
              label: '路凼',
              value: 7
            },
            {
              label: '其他',
              value: 8
            }
          ]
        }
      ]
    },
    {
      label: '香港',
      value: 33,
      children: [
        {
          label: '香港',
          value: 0,
          children: [
            {
              label: '深水埗区',
              value: 0
            },
            {
              label: '油尖旺区',
              value: 1
            },
            {
              label: '九龙城区',
              value: 2
            },
            {
              label: '黄大仙区',
              value: 3
            },
            {
              label: '观塘区',
              value: 4
            },
            {
              label: '北区',
              value: 5
            },
            {
              label: '大埔区',
              value: 6
            },
            {
              label: '沙田区',
              value: 7
            },
            {
              label: '西贡区',
              value: 8
            },
            {
              label: '元朗区',
              value: 9
            },
            {
              label: '屯门区',
              value: 10
            },
            {
              label: '荃湾区',
              value: 11
            },
            {
              label: '葵青区',
              value: 12
            },
            {
              label: '离岛区',
              value: 13
            },
            {
              label: '中西区',
              value: 14
            },
            {
              label: '湾仔区',
              value: 15
            },
            {
              label: '东区',
              value: 16
            },
            {
              label: '南区',
              value: 17
            },
            {
              label: '其他',
              value: 18
            }
          ]
        }
      ]
    }
  ]
}
